<template>
<div class="pitch-us h-screen relative">
  <div class="pitch-circle-sprite max-w-[736px] w-full max-h-[736px] h-full bg-[transparent] fixed border-solid border-[#e6e9e9] border-[1px] rounded-full top-[-530px] right-[-110px] lg:right-[200px] md:top-[-390px] opacity-0 z-10"></div>
  <div class="pitch-square-sprite max-w-[470px] w-full max-h-[470px] h-full bg-[transparent] fixed border-solid border-[#e6e9e9] border-[1px] transform rotate-[55deg] left-[300px] bottom-[-450px] opacity-0 z-10"></div>
  <main class="mx-auto md:max-w-[85%] max-w-[95%] w-screen px-[16px] pt-[100px] md:pt-0 flex h-full justify-start sm:justify-center">
    <div class="grid grid-cols-1 md:justify-center w-full">
      <div class="flex md:flex-row flex-col justify-start items-center">
        <div class="flex flex-col justify-start md:justify-center px-[16px] md:w-[50%] w-[100%]">
          <h1 class="pitch-heading max-w-[95%] font-normal text-[28px] md:text-[32px] lg:text-[57px] leading-[40px] md:leading-[45px] lg:leading-[64px] mb-3 md:mb-5 z-50">{{ $t("pitchUs.heading") }}</h1>
          <p class="pb-[41px] md:pb-[58px] md:text-[18px]">{{ $t("pitchUs.brief") }}</p>
          <a href="https://onyx.kinfolk.vc" target="_blank">
            <button class="pitch-more-btn relative w-[max-content] pb-[10px] md:m-0 font-medium text-[12px] md:text-[17px] flex items-center">
              {{ $t("pitchUs.button") }}
              <span class="right-arrow w-[15px] h-[15px] md:w-[24px] md:h-[24px] ml-[12px]"></span>
            </button>
          </a>
        </div>
        <div class="flex md:flex-row flex-col-reverse justify-center items-center mt-[50px] max-h-[600px] md:w-[50%] w-full">
          <img src="../assets/Pitch_Us.webp" alt="pitch background" width="100%" height="auto" class="min-w-[300px] w-[90%] h-[90%] md:mb-0 mb-8 z-[11]" />
        </div>
      </div>
    </div>
  </main>
</div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  async mounted () {
    gsap.fromTo('.pitch-circle-sprite', { opacity: 0, scaleX: 2, scaleY: 2 }, { opacity: 1, scaleX: 1, scaleY: 1, delay: 0.5, duration: 2 })
    gsap.fromTo('.pitch-square-sprite', { opacity: 0, scaleX: 2, scaleY: 2 }, { opacity: 1, scaleX: 1, scaleY: 1, delay: 0.5, duration: 2.1 })
    gsap.fromTo('.pitch-more-btn', { opacity: 0 }, { opacity: 1, delay: 4, duration: 1 })
  }
}
</script>

<style scoped>
.pitch-us {
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  display: none;
}
.pitch-more-btn::before, .pitch-more-btn::after {
  content: "";
  width: 0%;
  height: 2px;
  background-color: black;
  position: absolute;
  bottom: 0;
  opacity: 0;
  transition: 1s cubic-bezier(0.42, 0.01, 0, 1.04);
}
.more-btn-white::before {
  content: "";
  width: 0%;
  height: 2px;
  background-color: black;
  position: absolute;
  bottom: 0;
  opacity: 0;
  transition: 1s cubic-bezier(0.42, 0.01, 0, 1.04);
}

.more-btn-white::after {
  background-color: #E5E6E6;
}

.pitch-more-btn::before {
  width: 100%;
  z-index: 1;
  opacity: 1 !important;
  background-color: #E5E6E6;
}
.pitch-more-btn::after {
  z-index: 5;
}

.pitch-more-btn:hover::after {
  width: 100%;
  opacity: 1;
  transition: 1s cubic-bezier(0.42, 0.01, 0, 1.04);
}
@media screen and (min-width: 2000px) {
  .pitch-heading {
    max-width: 60%;
    line-height: 80px;
  }
}
@media screen and (min-width: 3000px) {
  .pitch-heading {
    max-width: 50%;
    line-height: 80px;

  }
}
</style>

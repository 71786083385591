<template>
  <div class="relative mx-auto md:max-w-[85%] max-w-[95%] w-screen px-[16px] pb-[50px] mt-[150px] md:mt-0">
    <div class="flex flex-col mt-[150px] mb-[50px]">
      <div class="heading mb-[60px]">
        <h1 class="lg:text-[56px] md:text-[40px] text-[34px] leading-[68px] text-center">
          {{$t('legal.privacyPolicy.heading')}}
        </h1>
      </div>
      <div class="content">
        <p v-html="$t('legal.privacyPolicy.brief1')"></p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <!-- <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          WHO WE ARE
        </h1>
      </div> -->
      <div class="content">
        <p v-html="$t('legal.privacyPolicy.brief2')"></p>
        <br>
        <p v-html="$t('legal.privacyPolicy.brief3')"></p>
        <br>
        <p>
          {{$t('legal.privacyPolicy.brief4')}}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.privacyPolicy.sub1.heading')}}
        </h1>
      </div>
      <div class="content">
        <p v-html="$t('legal.privacyPolicy.sub1.par1')"></p>
        <br>
        <p v-html="$t('legal.privacyPolicy.sub1.par2')"></p>

        <br>
        <p v-html="$t('legal.privacyPolicy.sub1.par3')"></p>

        <br>
        <p v-html="$t('legal.privacyPolicy.sub1.par4')"></p>

        <br>
        <p v-html="$t('legal.privacyPolicy.sub1.par5')"></p>

        <br>
        <p v-html="$t('legal.privacyPolicy.sub1.par6')"></p>

        <div class="w-full overflow-hidden overflow-x-auto">
          <table class="my-6 border-[1px] border-black min-w-[600px]">
            <thead>
              <tr class="border-b-[1px] border-black">
                <th class="border-r-[1px] border-black p-3 w-[33.3%]">Category of Personal Information</th>
                <th class="border-r-[1px] border-black p-3 w-[33.3%]">Examples of Personal Information We Collect</th>
                <th class="border-r-[1px] border-black p-3 w-[33.3%]">Categories of Third Parties With Whom We Share this Personal Information:</th>
              </tr>
            </thead>
            <tbody class="font-light">
              <tr class="border-b-[1px] border-black">
                <td class="border-r-[1px] border-black p-3">Profile or Contact Data</td>
                <td class="border-r-[1px] border-black p-3">
                  <ul class="list-disc ml-4">
                    <li>First and last name</li>
                    <li>Email</li>
                    <li>Phone number</li>
                    <li>Company name</li>
                    <li>Title</li>
                  </ul>
                </td>
                <td class="border-r-[1px] border-black p-3">
                  <ul class="list-disc ml-4">
                    <li>Service Providers</li>
                    <li>Business Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr class="border-b-[1px] border-black">
                <td class="border-r-[1px] border-black p-3">Device/IP Data</td>
                <td class="border-r-[1px] border-black p-3">
                  <ul class="list-disc ml-4">
                    <li>IP address</li>
                    <li>Device ID</li>
                    <li>Domain server</li>
                    <li>Type of device/operating system/browser used to access the Site</li>
                  </ul>
                </td>
                <td class="border-r-[1px] border-black p-3">
                  <ul class="list-disc ml-4">
                    <li>Service Providers</li>
                    <li>Business Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr class="border-b-[1px] border-black">
                <td class="border-r-[1px] border-black p-3">Web Analytics</td>
                <td class="border-r-[1px] border-black p-3">
                  <ul class="list-disc ml-4">
                    <li>Web page interactions</li>
                    <li>Referring webpage/source through which you accessed the Site</li>
                    <li>Non-identifiable request IDs</li>
                    <li>Statistics associated with the interaction between device or browser and the Site</li>
                  </ul>
                </td>
                <td class="border-r-[1px] border-black p-3">
                  <ul class="list-disc ml-4">
                    <li>Service Providers</li>
                    <li>Business Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
              <tr class="border-b-[1px] border-black">
                <td class="border-r-[1px] border-black p-3">Other Identifying Information that You Voluntarily Choose to Provide</td>
                <td class="border-r-[1px] border-black p-3">
                  <ul class="list-disc ml-4">
                    <li>Identifying information in emails or letters you send us</li>
                  </ul>
                </td>
                <td class="border-r-[1px] border-black p-3">
                  <ul class="list-disc ml-4">
                    <li>Service Providers</li>
                    <li>Business Partners</li>
                    <li>Parties You Authorize, Access or Authenticate</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br>

        <p>
          <strong class="font-medium">EU/UK Disclosure:</strong> Where we require Personal Data to comply with our legal or contractual obligations, the provision of such Personal Data is mandatory. If it is not provided, then we may not be able to provide the Internet Services. In all other cases, the provision of requested Personal Data is optional.
        </p>
        <br>
        <p>
          <strong class="font-medium">EU/UK Disclosure:</strong> We will not use Personal Data to formulate a decision based solely on automated processing (including profiling) which produces legal effects or similarly significantly impacts you.
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{ $t('legal.privacyPolicy.sub2.heading') }}
        </h1>
      </div>
      <div class="content">
        <p>
          {{$t('legal.privacyPolicy.sub2.par1')}}
        </p>
        <br>
        <p>
          {{$t('legal.privacyPolicy.sub2.par2')}}
        </p>
        <br>
        <p v-html="$t('legal.privacyPolicy.sub2.par3')">
        </p>
        <br>
        <p v-html="$t('legal.privacyPolicy.sub2.par4')">
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.privacyPolicy.sub3.heading')}}
        </h1>
      </div>
      <div class="content">
        <p v-html="$t('legal.privacyPolicy.sub3.par1')">
        </p>
        <br>
        <p v-html="$t('legal.privacyPolicy.sub3.par2')">
        </p>
        <br>
        <p>
          {{$t('legal.privacyPolicy.sub3.par3')}}
        </p>
        <br>
        <p v-html="$t('legal.privacyPolicy.sub3.par4')">
        </p>
        <br>
        <p v-html="$t('legal.privacyPolicy.sub3.par5')">
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.privacyPolicy.sub4.heading')}}
        </h1>
      </div>
      <div class="content">
        <p v-html="$t('legal.privacyPolicy.sub4.par1')">
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.privacyPolicy.sub5.heading')}}
        </h1>
      </div>
      <div class="content">
        <p>
          {{$t('legal.privacyPolicy.sub5.par1')}}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.privacyPolicy.sub6.heading')}}
        </h1>
      </div>
      <div class="content">
        <p>
          {{$t('legal.privacyPolicy.sub6.par1')}}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.privacyPolicy.sub7.heading')}}
        </h1>
      </div>
      <div class="content">
        <p v-html="$t('legal.privacyPolicy.sub7.par1')">
        </p>
        <br>
        <p>{{$t('legal.privacyPolicy.sub7.par2')}}</p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.privacyPolicy.sub8.heading')}}
        </h1>
      </div>
      <div class="content">
        <p>
          {{$t('legal.privacyPolicy.sub8.par1')}}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.privacyPolicy.sub9.heading')}}
        </h1>
      </div>
      <div class="content">
        <p>
          {{$t('legal.privacyPolicy.sub9.par1')}}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.privacyPolicy.sub10.heading')}}
        </h1>
      </div>
      <div class="content">
        <p v-html="$t('legal.privacyPolicy.sub10.par1')">
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.privacyPolicy.sub11.heading')}}
        </h1>
      </div>
      <div class="content">
        <p>
          {{$t('legal.privacyPolicy.sub11.par1')}}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.privacyPolicy.sub12.heading')}}
        </h1>
      </div>
      <div class="content">
        <p v-html="$t('legal.privacyPolicy.sub12.par1')">
        </p>
        <p>{{$t('legal.privacyPolicy.sub12.par2')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
</style>

<template>
  <div class="about relative overflow-hidden">

    <div class="grid grid-cols-1 lg:grid-cols-13 h-screen">
      <div class="relative lg:col-span-6 bg-black h-[150px] md:h-[200px] lg:h-screen z-50 saturate-[1.1]">
        <div id="progressBarContainer" class="hidden lg:block">
          <div id="progressBar"></div>
        </div>
        <!-- <transition name="fade"> -->
          <img id="image-slider" :src="require(`@/assets/${imageName}`)" alt="" width="100%" class="h-[inherit] slide-in object-cover lg:h-full" >
        <!-- </transition> -->
      </div>
      <div class="lg:col-span-6 bg-white h-[max-content] lg:h-screen relative">
        <div class="main-circle-sprite max-w-[736px] w-full max-h-[736px] h-full bg-[transparent] absolute border-solid border-[#cfd1d1] border-opacity-25 border-[1px] rounded-full top-[-430px] right-[-110px] lg:left-[400px] md:top-[-220px] opacity-1 z-10"></div>
      <!-- <div class="square-sprite max-w-[470px] w-full max-h-[470px] h-full bg-[transparent] absolute border-solid border-[#cfd1d1] border-opacity-25 border-[1px] transform rotate-[55deg] left-[200px] bottom-[-350px] opacity-0"></div> -->
        <div class="flex flex-col relative justify-between h-screen top-0 lg:mt-[120px] md:mt-[40px] pt-[16px] overflow-hidden z-50">
          <div class="heading md:px-[48px] px-[16px] pb-4">
            <h1 class="lg:text-[56px] md:text-[40px] text-[34px] leading-[68px]">{{$t("about.title")}}</h1>
          </div>
          <div id="content" class="md:px-[30px] px-[16px] md:!pl-[48px] !pl-4 pb-[150px] min-h-[calc(100vh-150px)] h-[max-content] lg:h-screen overflow-y-scroll">
            <div class="data pt-[20px] min-h-[max-content]">
              <router-view></router-view>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import i18n from '../i18n'
export default {
  data () {
    return {
      imageName: this.imageSetter()
    }
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler () {
        this.setImage()
      }
    }
  },
  mounted () {
    // this.imageSetter()
    const progressBarContainer = document.querySelector('#progressBarContainer')
    const progressBar = document.querySelector('#progressBar')
    const scrollableDiv = document.querySelector('#content')
    const mainContent = document.querySelector('#content > .data')
    let totalPageHeight = mainContent.offsetHeight - window.innerHeight
    let debounceResize
    // console.log(navigator.language.split('-')[0])
    scrollableDiv.addEventListener('scroll', () => {
      const newProgressHeight = scrollableDiv.scrollTop / totalPageHeight
      progressBar.style.transform = `scale(1.2,${newProgressHeight})`
      // console.log(`${i18n.locale}/about`)
      switch (this.$route.path) {
        case `/${i18n.locale}/about`:
          switch (true) {
            case newProgressHeight > 0 && newProgressHeight < 0.15:
              if (window.innerWidth < 977) {
                this.imageName = 'Philosophy_landscape.png'
              } else {
                this.imageName = 'Philosophy.png'
              }
              break
            case newProgressHeight > 0.16 && newProgressHeight < 0.57:
              if (window.innerWidth < 977) {
                this.imageName = 'Investment_Strategy_landscape.png'
              } else {
                this.imageName = 'Investment_Strategy.png'
              }
              break
            case newProgressHeight > 0.57:
              if (window.innerWidth < 977) {
                this.imageName = 'Impact_Commitments_landscape.png'
              } else {
                this.imageName = 'Impact_Commitments.png'
              }
              break
          }
          break
      }
    })
    scrollableDiv.addEventListener('resize', () => {
      clearTimeout(debounceResize)
      debounceResize = setTimeout(() => {
        totalPageHeight = scrollableDiv.offsetHeight - window.innerHeight
      }, 250)
    })
    progressBarContainer.addEventListener('click', (e) => {
      const newPageScroll = e.clientY / progressBarContainer.offsetHeight * totalPageHeight
      window.scrollTo({
        top: newPageScroll,
        behavior: 'smooth'
      })
    })
    gsap.fromTo('.main-circle-sprite', { opacity: 0, scaleX: 2, scaleY: 2 }, { opacity: 1, scaleX: 1, scaleY: 1, delay: 0.5, duration: 2 })
  },
  methods: {
    setImage () {
      switch (this.$route.path) {
        case `/${i18n.locale}/about/philosophy`:
          if (window.innerWidth < 977) {
            this.imageName = 'Philosophy_landscape.png'
          } else {
            this.imageName = 'Philosophy.png'
          }
          break
        case `/${i18n.locale}/about/impact-commitments`:
          if (window.innerWidth < 977) {
            this.imageName = 'Impact_Commitments_landscape.png'
          } else {
            this.imageName = 'Impact_Commitments.png'
          }
          break
        case `/${i18n.locale}/about/investment-strategy`:
          if (window.innerWidth < 977) {
            this.imageName = 'Investment_Strategy_landscape.png'
          } else {
            this.imageName = 'Investment_Strategy.png'
          }
          break
      }
    },
    imageSetter () {
      if (window.innerWidth < 977) {
        // console.log('below mark')
        return 'Philosophy_landscape.png'
      } else {
        return 'Philosophy.png'
      }
    }
  }
}
</script>

<style scoped>
.about {
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  display: none;
}
#image-slider {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
}

.slide-in {
    animation: slide-in 1.5s forwards;
    -webkit-animation: slide-in 1.5s forwards;
}

@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}
.show {
  transition: opacity 400ms;
}
.hide {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity .1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>

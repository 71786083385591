<template>
  <div>
    <div
      id="philosophy"
      class="lg:h-[max-content] md:pt-[60px] pt-[30px] lg:mt-[0] min-h-[max-content]"
    >
      <div class="subheading">
        <b class="text-[28px] block font-medium leading-[33px] mb-[24px]"
          >{{ $t("about.philosophy.heading") }}</b
        >
      </div>
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.philosophy.par") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.philosophy.par2") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.philosophy.par3") }}
      </p>
    </div>
    <div
      id="investment-strategy"
      class="lg:h-[max-content] mt-[40px] lg:mt-[135px] min-h-[500px]"
    >
      <div class="subheading">
        <b class="text-[28px] block font-medium leading-[33px] mb-[24px]"
          >{{ $t("about.investmentStrategy.heading") }}</b
        >
      </div>
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.investmentStrategy.par") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.investmentStrategy.par2") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.investmentStrategy.par3") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.investmentStrategy.par4") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.investmentStrategy.par5") }}
      </p>
    </div>
    <div
      id="impact-commitments"
      class="lg:h-[max-content] mt-[40px] lg:mt-[135px] min-h-[500px] pb-[150px]"
    >
      <div class="subheading">
        <b class="text-[28px] block font-medium leading-[33px] mb-[24px]"
          >{{ $t("about.impactCommitments.heading") }}</b
        >
      </div>
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.impactCommitments.par") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.impactCommitments.par2") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.impactCommitments.par3") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.impactCommitments.par4") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.impactCommitments.par5") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.impactCommitments.par6") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
</style>

<template>
  <div id="the-team" class="relative overflow-x-hidden w-full">
    <div class="team-main-circle-sprite max-w-[736px] w-[500px] max-h-[736px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] rounded-full top-[-530px] right-[-110px] lg:right-[130px] md:top-[-390px] opacity-0"></div>
    <div class="main-container flex flex-col w-full relative">
      <div class="top min-h-[100vh] relative flex flex-col items-center justify-center">
        <div class="main pt-[20px] mx-auto md:max-w-[85%] max-w-[95%]">
          <h1 class="font-serif font-normal text-[34px] md:text-[56px] leading-[40px] md:leading-[68px] text-center my-[40px] mt-[150px] md:mt-[70px] lg:mt-[70px]">{{ $t("team.name") }}</h1>
          <div class="gallery w-full px-[10px] md:px-[20px] pb-[30px] md:overflow-y-[unset]">
            <div class="grid md:grid-cols-3 grid-cols-2 md:gap-0 gap-[20px] xl:w-[calc(97vw-440px)]">
              <div v-for="member in teamMembers" :key="member.id" class="member-card px-0 md:px-[20px] py-[10px] md:py-[20px] relative overflow-visible md:overflow-hidden rounded-[2px] grayscale hover:grayscale-0 h-[fit-content] w-full">
                <router-link :to="{
                    name: 'MemberDetails',
                    params: { id: member.path }}">
                  <div class="team-member-image-container w-full relative overflow-hidden md:hover:rounded-[2px]">
                    <img :src="member.url" alt="" width="100%" class="w-full h-full object-cover"/>
                  </div>
                  <div class="details pt-[10px] md:pt-[20px]">
                    <h4 class="md:mb-[8px] mb-0 lg:text-[20px] md:text-[18px] text-[12px] text-center font-bold leading-[27px] sm:leading-[16px]">
                      <template v-if="member.memberName.includes('Toni')">
                        {{ $t("team.toni.name", { par: member.memberName}) }}
                      </template>
                      <template v-if="member.memberName.includes('Faithful')">
                        {{ $t("team.faithful.name", { par: member.memberName}) }}
                      </template>
                      <template v-if="member.memberName.includes('Chukwu')">
                        {{ $t("team.chukwu.name", { par: member.memberName}) }}
                      </template>
                      <template v-if="member.memberName.includes('Ogo')">
                        {{ $t("team.ogo.name", { par: member.memberName}) }}
                      </template>
                      <template v-if="member.memberName.includes('Jay')">
                        {{ $t("team.jay.name", { par: member.memberName}) }}
                      </template>
                    </h4>
                    <p class="text-center lg:text-[18px] md:text-[14px] text-[10px]">
                      <template v-if="member.memberName.includes('Toni')">
                        {{ $t("team.toni.role", { par: member.position}) }}
                      </template>
                      <template v-if="member.memberName.includes('Faithful')">
                        {{ $t("team.faithful.role", { par: member.position}) }}
                      </template>
                      <template v-if="member.memberName.includes('Chukwu')">
                        {{ $t("team.chukwu.role", { par: member.position}) }}
                      </template>
                      <template v-if="member.memberName.includes('Ogo')">
                        {{ $t("team.ogo.role", { par: member.position}) }}
                      </template>
                      <template v-if="member.memberName.includes('Jay')">
                        {{ $t("team.jay.role", { par: member.position}) }}
                      </template>
                    </p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Carousel from '@/components/Carousel'
// import CarouselSlide from '@/components/CarouselSlide'
import { gsap } from 'gsap'
import { team } from '@/utils/team'
export default {
  data () {
    return {
      teamMembers: team
    }
  },
  async mounted () {
    // eslint-disable-next-line no-unused-vars
    // const totalPageHeight = document.body.scrollHeight - window.innerHeight
    this.animateSprites()
    // gsap.fromTo('.team-square-sprite', { opacity: 0, scaleX: 2, scaleY: 2 }, { opacity: 1, scaleX: 1, scaleY: 1, delay: 1, duration: 2.1 })
  },
  methods: {
    animateSprites () {
      gsap.fromTo('.team-main-circle-sprite', { opacity: 0, scaleX: 2, scaleY: 2 }, { opacity: 1, scaleX: 1, scaleY: 1, delay: 1, duration: 2 })
    },
    getMemberByLocale (member) {
      const name = member.memberName
      const position = member.position
      console.log(name, member, this.$t('team'))
      return {
        name,
        position
      }
    }
  }
}
</script>

<style scoped>
#scrollBarContainer {
  position: absolute;
  left: 80px;
  top: 150px;
  z-index: 10;
  overflow: hidden;
}

#scrollBar {
  position: absolute;
  top: 0;
  will-change: transform, opacity;
  transform-origin: top center;
  transform: scale(1, 0);
}

#scrollBarContainer,
#scrollBar {
  right: 0;
  bottom: 0;
  width: 2px;
  height: 400px;
}

</style>

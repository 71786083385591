<template>
  <div class="max-w-[1440px] font-serif">
    <div
      class="flex top-0 w-screen mx-auto left-0 fixed px-5 md:py-[26px] py-[12px] z-[500]"
      :class="[
        isSelectedforUIToggles && !isDark ? 'bg-[#ffffffd1] backdrop-blur-[3px] transition delay-[900ms]' : 'bg-[transparent]',
        (scrollY > 0 && !isDark) ? 'drop-shadow-md' : '',
      ]"
    >
        <header
          class="flex items-center justify-between w-screen md:max-w-[85%] max-w-[95%] mx-auto relative"
        >
          <div class="flex justify-between items-center z-[500]"
            >
            <router-link :to="`/${$i18n.locale}`" :class="isNavOpen ? 'invisible' : ''">
              <img
                v-if="!isDark"
                src="../assets/kinfolk.svg"
                alt=""
                style="width: 40px; height: 40px;"
              />
              <img
                v-if="isDark"
                src="../assets/kinfolkwhite.svg"
                alt=""
                style="width: 40px; height: 40px;"
              />
            </router-link>
          </div>
          <!-- <span class="text-[red]">{{isSelectedforUIToggles}}, {{isDark}}, {{parseRouteName}}</span> -->

          <div
            v-if="isNavOpen"
            class="open-overlay open cursor-pointer flex items-center"
          >
          <LocaleChanger />
            <div @click="handleNav" class="hamburger flex rounded-full">
              <div class="hamburger-track closed font-sans">
                <span style="color: black"> {{ $t("nav.close") }} </span>
              </div>
              <div class="hamburger-button closed">
                <div class="lines closed">
                  <span class="bar" />
                  <span class="bar" />
                  <span class="bar" />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!isNavOpen"
            class="open-overlay closed cursor-pointer flex items-center"
          >
          <LocaleChanger />
            <div @click="handleNav" class="hamburger flex rounded-full">
              <div class="hamburger-track font-sans">
                <span style="color: black; line-height: 1"> {{ $t("nav.open") }} </span>
              </div>
              <div class="hamburger-button">
                <div class="lines">
                  <span class="bar" />
                  <span class="bar" />
                  <span class="bar" />
                </div>
              </div>
            </div>
          </div>
        </header>
    </div>
    <div class="overlay-navigation" style="opacity: 0; visibility: hidden">
      <nav
        class="nav-container"
        role="navigation"
        style="background-color: transparent"
      >
        <ul id="navColumnsWrapper">
          <li>
            <div
              class="navitem-container flex h-full flex-col items-center justify-center"
            >
              <div class="navitem-title mb-0 md:mb-[50px] w-full">
                <CustomRouteLink location="about">
                  <span
                    class="nav-text font-Playfair text-white font-normal leading-[55px] mb-0 lg:mb-[20px]"
                    @click="handleNav"
                    data-content="About"
                    >{{ $t("nav.about.name") }}</span
                  >
                </CustomRouteLink>
              </div>
              <div
                class="navitem-destinations overflow-hidden w-full h-0 hidden md:block"
              >
                <CustomRouteLink location="about/philosophy">
                  <span
                    class=" font-Playfair text-sm font-normal"
                    data-content="Philosophy"
                    @click="handleNav"
                    >{{ $t("nav.about.philosophy") }}</span
                  >
                </CustomRouteLink>
                <CustomRouteLink location="about/investment-strategy">
                  <span
                    class=" font-Playfair text-sm font-normal"
                    data-content="Investment Strategy"
                    @click="handleNav"
                    >{{ $t("nav.about.investmentStrategy") }}</span
                  >
                </CustomRouteLink>
                <CustomRouteLink location="about/impact-commitments">
                  <span
                    class=" font-Playfair text-sm font-normal"
                    data-content="Impact Commitments"
                    @click="handleNav"
                    >{{ $t("nav.about.impact") }}</span
                  >
                </CustomRouteLink>
                <CustomRouteLink location="terms-of-use">
                  <span
                    class=" font-Playfair text-sm font-normal"
                    data-content="terms and conditions"
                    @click="handleNav"
                    >{{ $t("nav.about.terms") }}</span
                  >
                </CustomRouteLink>
                <CustomRouteLink location="privacy-policy">
                  <span
                    class=" font-Playfair text-sm font-normal"
                    data-content="privacy policy"
                    @click="handleNav"
                    >{{ $t("nav.about.privacyPolicy") }}</span
                  >
                </CustomRouteLink>
                <CustomRouteLink location="anti-harassment-policy">
                  <span
                    class=" font-Playfair text-sm font-normal"
                    data-content="privacy policy"
                    @click="handleNav"
                    >{{ $t("nav.about.antiHarassment") }}</span
                  >
                </CustomRouteLink>
              </div>
            </div>
          </li>
          <li>
            <div
              class="navitem-container flex h-full flex-col items-center justify-center"
            >
              <div class="navitem-title mb-0 md:mb-[50px] w-full">
                <CustomRouteLink location="team">
                  <span
                    class="nav-text font-Playfair text-white font-normal leading-[55px] mb-0 lg:mb-[20px]"
                    @click="handleNav"
                    >{{ $t("nav.team.name") }}</span
                  >
                </CustomRouteLink>
              </div>
              <div
                class="navitem-destinations overflow-hidden w-full h-0 hidden sm:block"
              >
                <CustomRouteLink location="team">
                  <span
                    class=" font-Playfair text-sm font-normal"
                    data-content="Partners"
                    @click="handleNav"
                    >{{ $t("nav.team.partners") }}</span
                  >
                </CustomRouteLink>
                <CustomRouteLink location="#">
                  <span
                    v-html='$t("nav.team.collective")'
                    class=" font-Playfair text-sm font-normal"
                    data-content="collective"
                    @click="handleNav"
                    ></span
                  >
                </CustomRouteLink>
              </div>
            </div>
          </li>
          <li>
            <div
              class="navitem-container flex h-full flex-col items-center justify-center"
               @click="handleNav"
            >
              <div class="navitem-title mb-0 md:mb-[50px] w-full">
                <CustomRouteLink location="companies" @click="handleNav">
                  <span
                    class="nav-text font-Playfair text-white font-normal leading-[55px] mb-0 lg:mb-[20px]"
                    >{{ $t("nav.companies.name") }}</span
                  >
                </CustomRouteLink>
              </div>
              <div
                class="navitem-destinations overflow-hidden w-full h-0 hidden sm:block"
              >
                <CustomRouteLink location="companies">
                  <span
                    class=" font-Playfair text-sm font-normal"
                    data-content="Portfolio"
                    @click="handleNav"
                    >{{ $t("nav.companies.portfolio") }}</span
                  >
                </CustomRouteLink>
                <a href="https://chronicles.kinfolk.vc/founder-profiles">
                  <span
                    v-html='$t("nav.companies.founderProfiles")'
                    class=" font-Playfair text-sm font-normal"
                    data-content="Founder Profiles"
                    @click="handleNav"
                    ></span
                  >
                </a>
              </div>
            </div>
          </li>
          <li>
            <div
              class="navitem-container flex h-full flex-col items-center justify-center"
            >
              <div class="navitem-title mb-0 md:mb-[50px] w-full">
                <CustomRouteLink :location="innerWidth < 1024 ? 'join-us' : 'contact'">
                  <span
                    class="nav-text font-Playfair text-white font-normal leading-[55px] mb-0 lg:mb-[20px]"
                    @click="handleNav"
                    >{{ $t("nav.connect.name") }}</span
                  >
                </CustomRouteLink>
              </div>
              <div
                class="navitem-destinations overflow-hidden w-full h-0 hidden sm:block"
              >
                <CustomRouteLink location="join-us">
                  <span
                    class=" font-Playfair text-sm font-normal"
                    data-content="Community By Design"
                    @click="handleNav"
                    >{{ $t("nav.connect.pitch") }}</span
                  >
                </CustomRouteLink>
                <CustomRouteLink location="contact">
                  <span
                    class=" font-Playfair text-sm font-normal"
                    data-content="Community By Design"
                    @click="handleNav"
                    >{{ $t("nav.connect.contact") }}</span
                  >
                </CustomRouteLink>
              </div>
            </div>
          </li>
          <li>
            <div
              class="navitem-container flex h-full flex-col items-center justify-center"
            >
              <div class="navitem-title mb-0 md:mb-[50px] w-full">
                <a href="https://chronicles.kinfolk.vc" @click="handleNav">
                  <span
                    class="nav-text font-Playfair text-white font-normal leading-[55px] mb-0 lg:mb-[20px]"
                    >{{ $t("nav.stories.name") }}</span
                  >
                </a>
              </div>
              <div
                class="navitem-destinations overflow-hidden w-full h-0 hidden sm:block"
              >
                <a href="https://chronicles.kinfolk.vc/recommended-reads">
                  <span
                    class="font-Playfair text-sm font-normal"
                    data-content="Family By Design"
                    @click="handleNav"
                    >{{ $t("nav.stories.recommended") }}</span
                  >
                </a>
                <a href="https://chronicles.kinfolk.vc/news">
                  <span
                    class="font-Playfair text-sm font-normal"
                    data-content="Family By Design"
                    @click="handleNav"
                    >{{ $t("nav.stories.news") }}</span
                  >
                </a>
                <a href="https://chronicles.kinfolk.vc/insights">
                  <span
                    class="font-Playfair text-sm font-normal"
                    data-content="Family By Design"
                    @click="handleNav"
                    >{{ $t("nav.stories.insights") }}</span
                  >
                </a>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import CustomRouteLink from './customRouteLink.vue'
import LocaleChanger from './LocaleChanger.vue'
import { gsap } from 'gsap'
export default {
  components: {
    CustomRouteLink,
    LocaleChanger
  },
  data () {
    return {
      isNavOpen: false,
      parseRouteName: '',
      isSelectedforUIToggles: false,
      scrollY: 0,
      innerWidth: ''
    }
  },
  methods: {
    handleNav () {
      this.isNavOpen = !this.isNavOpen
      this.navAnimations()
      if (!this.isNavOpen) {
        store.dispatch('setDark', false)
        this.navAnimations()
      } else {
        store.dispatch('setDark', true)
        this.navAnimations()
      }
    },
    decideIfNavbarIsInvisible (path, routeName) {
      this.parseRouteName = routeName.toLowerCase().replace(/\s+/g, '-')
      // console.log(routeName, 'wiejfioew')
      if (this.parseRouteName === 'terms-of-use') {
        this.isSelectedforUIToggles = path.includes(this.parseRouteName)
      } else if (this.parseRouteName === 'privacy-policy') {
        this.isSelectedforUIToggles = path.includes(this.parseRouteName)
      } else if (this.parseRouteName === 'team') {
        this.isSelectedforUIToggles = path.includes(this.parseRouteName)
      } else {
        this.isSelectedforUIToggles = false
      }
      return this.isSelectedforUIToggles
    },
    navAnimations () {
      const cols = gsap.utils.toArray('#navColumnsWrapper')
      const animateNavItemsIn = () => {
        // console.log(cols)
        cols.forEach((col) => {
          gsap.fromTo(
            col.querySelectorAll('li'),
            { autoAlpha: 0 },
            { autoAlpha: 1, duration: 0.8, stagger: 0.2 }
          )
        })
      }
      const animateNavItemsOut = () => {
        cols.forEach((col) => {
          gsap.fromTo(
            col.querySelectorAll('li'),
            { autoAlpha: 1 },
            { autoAlpha: 0, duration: 0.8, stagger: -0.2 }
          )
        })
      }
      if (this.isNavOpen) {
        gsap.fromTo(
          '.overlay-navigation',
          { autoAlpha: 0 },
          { autoAlpha: 1, duration: 0.1 }
        )
        gsap.fromTo(
          '.nav-container',
          { backgroundColor: 'transparent' },
          { backgroundColor: '#000000', duration: 2 }
        )
        animateNavItemsIn()
      } else {
        gsap.fromTo(
          '.overlay-navigation',
          { autoAlpha: 1 },
          { autoAlpha: 0, duration: 1.3 }
        )
        gsap.fromTo(
          '.nav-container',
          { backgroundColor: '#000000' },
          { backgroundColor: 'transparent', duration: 1.5 }
        )
        animateNavItemsOut()
      }
    },
    handleScroll () {
      this.scrollY = window.scrollY
    },
    handleWidth () {
      this.innerWidth = window.innerWidth
    }
  },
  computed: {
    isDark () {
      return store.getters.getDark
    }
  },
  mounted () {
    this.handleScroll()
    this.handleWidth()
    document.addEventListener('scroll', () => {
      this.handleScroll()
    })
    document.addEventListener('load', () => {
      this.handleWidth()
    })
  },
  watch: {
    '$route.path': {
      immediate: true,
      handler () {
        this.decideIfNavbarIsInvisible(this.$route.path, this.$route.name)
      }
    }
  }
}
</script>

<style scoped></style>

<template>
  <div id="app">
    <template>
      <transition name="fadeInOut">
        <Loading v-if="isLoading && visited === false"></Loading>
      </transition>
    </template>
    <main class="h-screen relative">
      <Navbar />
      <router-view/>
    </main>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Loading from '@/components/Loading.vue'
import store from '@/store'
import countapi from 'countapi-js'

export default {
  components: {
    Navbar,
    Loading
  },
  data () {
    return {
      isLoading: true,
      visited: sessionStorage.getItem('visits') > 1
    }
  },
  mounted () {
    setTimeout(() => {
      this.isLoading = false
    }, 3000)
    const d = store.getters.getLoaded
    console.log(d)
    setTimeout(() => {
      this.$store.dispatch('setLoaded')
    }, 3000)
  },
  computed: {
    loadedVal () {
      const d = store.getters.getLoaded
      console.log(d)
      return store.getters.getLoaded
    }
  },
  created () {
    countapi.get('kinfolk.vc', 'load').then((result) => {
      let totalVisits = result.value
      console.log(totalVisits)
      if (sessionStorage.getItem('visits')) {
        countapi.hit('kinfolk.vc', 'load').then((result) => {
          totalVisits = result.value
          sessionStorage.setItem('visits', totalVisits)
        })
      }
      sessionStorage.setItem('visits', totalVisits)
    })
  },
  watch: {
    '$route.path': {
      immediate: true,
      deep: true,
      handler () {
        if (window.innerWidth < 1024) {
          // dark color logo
          if (this.$route.path === '/about' || this.$route.path === '/team' || this.$route.path === '/team/toni' || this.$route.path === '/team/jay' || this.$route.path === '/pitch-us') {
            store.dispatch('setDark', false)
          }
        } else {
          // white color logo
          if (this.$route.path === '/companies' || this.$route.path === '/contact') {
            store.dispatch('setDark', true)
          }
        }
      }
    },
    '': {}
  }
}
</script>
<style>
.fadeInOut-enter, .fadeInOut-leave-to {
  opacity: 0;
}
.fadeInOut-enter-active, .fadeInOut-leave-active {
  transition: all .5s ease-in-out;
}
</style>

export const team = [
  {
    id: 1,
    url: require('../assets/toni.webp'),
    path: 'toni',
    memberName: 'Toni Campbell',
    position: 'Founder & Managing Partner',
    personalQuote: '',
    paragraphOne: 'Oluwatoni “Toni” Campbell is a Partner at Kinfolk Venture Capital. Toni is Harvard trained both as an undergrad, and in Business School.',
    paragraphTwo: 'Toni is ex Bessemer Venture Partners, Seraph Group, TastingRoom, Harvard Crimson, and Arcadia Funds.',
    paragraphThree: 'At Bessemer Venture Partners, Toni built a network of 3000+ companies that led to $36M being deployed in 5 companies he sourced.',
    paragraphFour: 'In his 3+ years at Arcadia, he engaged and executed credit transactions totaling nearly $700M in invested capital.'
  },
  {
    id: 2,
    url: require('../assets/faithful_ojebiyi.webp'),
    path: 'faithful',
    memberName: 'Faithful Ojebiyi',
    position: 'Partner & Engineering Lead',
    personalQuote: 'I firmly believe that Africa can become the next Silicon Valley by expanding and strengthening the software space.',
    paragraphOne: "Some of my colleagues will deliberately ask me what I enjoy doing outside of coding because, if they don't rule it out first, that’s what I'll say in response. I love programming and all of its challenges, I think of it as my passion because of the indescribable excitement it gives me. I also enjoy attempting to understand things and looking for straightforward solutions to complex problems. When it comes to being a developer, I like to think of myself as a perfectionist.",
    paragraphTwo: 'I studied physics at the Federal University of Agriculture in Abeokuta, and I have always had a keen interest in adhering to engineering best practices when architecting software. I started as an embedded software engineer, writing code for micro controllers and micro processors, and I have worked on building a multi-vendor e-commerce application and also building some other digital platforms in my previous establishments. Currently, I have over five years of experience developing scalable NodeJS applications.',
    paragraphThree: 'As Lead Engineer for Kinfolk Venture Capital, it is my responsibility to lead and direct the application team in all aspects of design, coding, testing, research, and analysis of developments. In addition, I make sure that everyone works in a dynamic and comfortable environment. In the long run, I hope to work alongside other founders to build a couple of startups.',
    paragraphFour: "My passion and area of greatest efficiency is engineering, so when I'm not working with the Kinfolk team in my spare time, you can find me coaching young engineers or aspiring software developers, contributing to open source projects, producing tutorials and guides for engineering blogs, and grooving to some classical or country music."
  },
  {
    id: 3,
    url: require('../assets/chukwu_ayika.webp'),
    path: 'chukwu',
    memberName: 'Chukwudalu Ayika',
    position: 'Frontend Lead',
    personalQuote: 'We all have the ability to create the kind of life we want to live.',
    paragraphOne: 'Personal experiences from being Nigerian-born educated me and led me to the opinion that specific opportunities and lifestyle choices should be available to everyone. Regardless of tribe, ethnicity, or religion, it should be possible for people to achieve their goals and aspirations. I want to think that my interest in software engineering stems from my lifelong curiosity.',
    paragraphTwo: "I had my education at Ecole Superieure D'Enseignement Professionnel Université in the Republic of Benin with a bachelor's degree in Business Administration and Management. Before settling on my current career, I tinkered with electronics and studied computer related skills such as sound engineering, music production, and graphic design.",
    paragraphThree: 'Prior to Kinfolk, I started my professional career at Stutern as a front-end developer, mentoring and assisting new and bright enthusiasts in their transition to tech; and Quabbly, Inc. - cutting-edge technology internal process management and automation tool for businesses as a Frontend Engineer. Through my work as a software developer, I have had the opportunity to discover my flair for art and design, which has, in many ways, helped me in certain aspects of my job.',
    paragraphFour: 'When I’m not looking at a screen or working on building products with the team, you might find me with close friends or at the pool.'
  },
  {
    id: 4,
    url: require('../assets/glory_adeoye.webp'),
    path: 'ogooluwa',
    memberName: 'Ogooluwa Adeoye',
    position: 'Content Lead',
    personalQuote: 'If you want to succeed, you should strike out on new paths rather than travel the worn paths of accepted success.',
    paragraphOne: 'One valuable lesson I have learned about life is that people can only become the best versions of themselves if they are willing to take risks and explore new paths. I am a fervent advocate of personal development and growth, and I can confidently discuss these topics because I have had my fair share of experience.',
    paragraphTwo: 'I graduated from Olabisi Onabanjo University in Ogun State with a degree in Accounting. Now, I work as a Content Writer for Kinfolk Venture Capital, where my responsibility is to create original and authentic content. When I recognized and acknowledged how discouraged I felt not doing what gave me satisfaction, I embraced a significant change in my profession.I have been writing stories since I was very young, and what I initially considered a hobby has now blossomed into a career.',
    paragraphThree: 'Before joining the Kinfolk team, I worked in an accounting firm as an External Audit Assistant, which gave me a significant opportunity to manage several top industries that were clients of my company. Through working with these clients, I gained vital research and investigational skills that have, in many ways, helped me advance in my journey of content research and writing. In particular, I have written articles and created social media content for the digital marketing industry while working as a freelance content writer on Fiverr and LinkedIn. In the long run, I want to establish myself as a recognized author in my chosen field.',
    paragraphFour: 'When I am not writing content, you can find me coaching young entrepreneurs on content development for brand building and creating awareness about different online businesses. In addition, you could catch me at networking events, unwinding, or watching a thriller or science fiction movie.'
  },
  {
    id: 5,
    url: require('../assets/jay.webp'),
    path: 'jay',
    memberName: 'Jay Freeman',
    position: 'Co-Founder & Advisor',
    personalQuote: '',
    paragraphOne: 'Kamarra “Jay” Freeman is a Partner at Kinfolk Venture Capital.',
    paragraphTwo: 'At PayPal, Jay was a Senior Global Product Manager across M&A teams focused on PayPal, Venmo, Xoom, and others.',
    paragraphThree: 'Jay also advised PayPal Ventures $530M Economic Opportunity Fund on how best to tackle structural issues by investing in early-stage Black and Latinx fund managers, entrepreneurs, and supporting organizations.',
    paragraphFour: 'Jay also founded Bourn.io, TinkerGrowth, and was a Techstars mentor and Republic Venture Partner.'
  }
]

export default team

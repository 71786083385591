<template>
  <div class="locale-changer w-full bg-white mr-6 p-1 rounded-full overflow-hidden">
    <div class="flex justify-center space-x-2">
      <button
        v-for="(lang, i) in i18nClient.availableLocales"
        :key="`langs${i}`"
        @click="routeLocale(lang)"
        :class="{'selectedLine border border-black': selectedLocale === lang}"
        class="w-[30px] h-[30px] p-1 cursor-pointer text-[12px] relative rounded-full overflow-hidden"
      >
        {{ lang.toUpperCase() }}
      </button>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n'

export default {
  data () {
    return {
      i18nClient: i18n,
      selectedLocale: localStorage.getItem('selectedLocale') || 'en'
    }
  },
  mounted () {
    // Set the initial locale based on the stored value or default to 'en'
    this.i18nClient.locale = this.selectedLocale
  },
  methods: {
    routeLocale (locale) {
      this.selectedLocale = locale
      this.i18nClient.locale = locale
      localStorage.setItem('selectedLocale', locale)
      this.$router.push({
        params: { lang: locale }
      })
    }
  }
}
</script>

<style scoped>
/* .selectedLine::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: black;
  color: white;
  position: absolute;
  bottom: 0;
  left: 0;
}
  */
</style>

<template>
  <div>
    <div class="mt-[30px] lg:mt-[0] mb-[80px]">
      <div class="subheading">
        <b class="text-[28px] block font-medium leading-[33px] mb-[24px]"
          >{{ $t("about.philosophy.heading") }}</b
        >
      </div>
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.philosophy.par") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.philosophy.par2") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.philosophy.par3") }}
      </p>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
</style>

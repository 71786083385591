import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoaded: false,
    visited: 0,
    isDark: false,
    isTweening: false
  },
  mutations: {
    setLoaded (state) {
      state.isLoaded = true
    },
    setDark (state, data) {
      state.isDark = data
    },
    setTweening (state, data) {
      state.isTweening = data
    },
    setVisited (state, data) {
      state.visited = data
    }
  },
  getters: {
    getLoaded (state) {
      return state.isLoaded
    },
    getDark (state) {
      return state.isDark
    },
    getTweening (state) {
      return state.isTweening
    },
    getVisited (state) {
      return state.visited
    }
  },
  actions: {
    setLoaded ({ commit }) {
      commit('setLoaded')
    },
    setDark ({ commit }, data) {
      commit('setDark', data)
    },
    setTweening ({ commit }, data) {
      commit('setTweening', data)
    },
    setVisited ({ commit }, data) {
      commit('setVisited', data)
    }
  },
  modules: {
  }
})

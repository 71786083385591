<template>
  <div class="relative mx-auto md:max-w-[85%] max-w-[95%] w-screen px-[16px] pb-[50px] mt-[150px] md:mt-0">
    <div class="flex flex-col mt-[150px] mb-[50px]">
      <div class="heading mb-[60px]">
        <h1 class="lg:text-[56px] md:text-[40px] text-[34px] leading-[68px] text-center">
          {{$t('legal.antiHarassment.heading')}}
        </h1>
      </div>
      <div class="content">
        <p v-html="$t('legal.antiHarassment.brief1')"></p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <!-- <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          WHO WE ARE
        </h1>
      </div> -->
      <div class="content">
        <p v-html="$t('legal.antiHarassment.brief2')"></p>
        <br>
        <!-- <p v-html="$t('legal.antiHarassment.brief3')"></p> -->
        <!-- <br> -->
        <!-- <p>
          {{$t('legal.antiHarassment.brief4')}}
        </p> -->
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.antiHarassment.sub1.heading')}}
        </h1>
      </div>
      <div class="content">
        <p v-html="$t('legal.antiHarassment.sub1.par1')"></p>
        <br>
        <!-- <p v-html="$t('legal.privacyPolicy.sub1.par2')"></p> -->

        <!-- <br>
        <p v-html="$t('legal.privacyPolicy.sub1.par3')"></p>

        <br>
        <p v-html="$t('legal.privacyPolicy.sub1.par4')"></p>

        <br>
        <p v-html="$t('legal.privacyPolicy.sub1.par5')"></p>

        <br>
        <p v-html="$t('legal.privacyPolicy.sub1.par6')"></p> -->
        <br>

        <!-- <p>
          <strong class="font-medium">EU/UK Disclosure:</strong> Where we require Personal Data to comply with our legal or contractual obligations, the provision of such Personal Data is mandatory. If it is not provided, then we may not be able to provide the Internet Services. In all other cases, the provision of requested Personal Data is optional.
        </p>
        <br>
        <p>
          <strong class="font-medium">EU/UK Disclosure:</strong> We will not use Personal Data to formulate a decision based solely on automated processing (including profiling) which produces legal effects or similarly significantly impacts you.
        </p> -->
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{ $t('legal.antiHarassment.sub2.heading') }}
        </h1>
      </div>
      <div class="content">
        <div v-html="$t('legal.antiHarassment.sub2.par1')">
        </div>
        <br>
        <p v-html="$t('legal.antiHarassment.sub2.par2')">
        </p>
        <br>
        <p v-html="$t('legal.antiHarassment.sub2.par3')">
        </p>
        <br>
        <p v-html="$t('legal.antiHarassment.sub2.par4')">
        </p>
        <br>
        <p v-html="$t('legal.antiHarassment.sub2.par5')">
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.antiHarassment.sub3.heading')}}
        </h1>
      </div>
      <div class="content">
        <p v-html="$t('legal.antiHarassment.sub3.par1')">
        </p>
        <!-- <br>
        <p v-html="$t('legal.privacyPolicy.sub3.par2')">
        </p> -->
        <br />
        <!-- <p>
          {{$t('legal.privacyPolicy.sub3.par3')}}
        </p>
        <br>
        <p v-html="$t('legal.privacyPolicy.sub3.par4')">
        </p>
        <br>
        <p v-html="$t('legal.privacyPolicy.sub3.par5')">
        </p> -->
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.antiHarassment.sub4.heading')}}
        </h1>
      </div>
      <div class="content">
        <p v-html="$t('legal.antiHarassment.sub4.par1')">
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-6">
      <div class="heading mb-[16px]">
        <h1 class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium">
          {{$t('legal.antiHarassment.sub5.heading')}}
        </h1>
      </div>
      <div class="content">
        <p>
          {{$t('legal.antiHarassment.sub5.par1')}}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="content">
        <p v-html="$t('legal.antiHarassment.sub6.par1')">
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
</style>

<template>
<div class="contact relative overflow-hidden">
  <div class="contact-circle-sprite max-w-[736px] w-full max-h-[736px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] rounded-full top-[-530px] right-[-110px] lg:right-[350px] md:top-[-290px] opacity-0"></div>
    <div class="grid grid-cols-1 lg:grid-cols-13 h-screen">
      <div class="relative lg:col-span-7 bg-black h-[150px] md:h-[180px] lg:h-screen">
        <!-- <div id="progressBarContainer" class="hidden lg:block">
          <div id="progressBar"></div>
        </div> -->
        <img id="contact-image-slider" src="../assets/sea-view_of_lekki_link_bridge.webp" alt="" width="100%" height="100%" class="h-[150px] md:h-[180px] slide-in object-cover lg:h-full" >
      </div>
      <div class="lg:col-span-5 bg-white lg:h-screen h-[calc(100vh-40%)]">
        <div class="flex flex-col relative justify-between max-h-[inherit] lg:px-[50px] px-[16px] h-full">
          <div class="heading px-0 lg:mt-[120px] md:mt-[40px] mt-[16px]">
            <h1 class="lg:text-[56px] md:text-[40px] text-[34px] leading-[68px]">{{ $t("contacts.name") }}</h1>
          </div>
          <div id="content" class="pb-[50px]">
            <div class="data">
              <div class="lg:h-[max-content]">
                <div class="subheading">
                  <b class="text-[20px] block font-medium leading-[24px] mb-[8px] mt-[34px]">{{ $t("contacts.email.label") }}</b>
                </div>
                <a href="mailto:hello@kinfolk.vc" class="text-[16px] sm:text-[18px] text-[#828282] font-normal leading-[26px] sm:leading-[32px]">{{ $t("contacts.email.emailAdd") }}</a>
              </div>
              <!-- <div class="lg:h-[max-content]">
                <div class="subheading">
                  <b class="text-[20px] block font-medium leading-[24px] mb-[8px] mt-[34px]">Telephone</b>
                </div>
                <p class="text-[16px] sm:text-[18px] text-[#828282] font-normal leading-[26px] sm:leading-[32px]">+234 819 822 7814</p>
              </div> -->
              <div class="lg:h-[max-content]">
                <div class="subheading">
                  <b class="text-[20px] block font-medium leading-[24px] mb-[8px] mt-[34px]">{{ $t("contacts.address.label") }}</b>
                </div>
                <p class="text-[16px] sm:text-[18px] text-[#828282] font-normal leading-[26px] sm:leading-[32px]">{{ $t("contacts.address.addy") }}</p><br>
                <p class="text-[16px] sm:text-[18px] text-[#828282] font-normal leading-[26px] sm:leading-[32px]">{{ $t("contacts.address.addy2") }}</p>
              </div>
              <div class="lg:h-[max-content]">
                <div class="subheading">
                  <b class="text-[20px] block font-medium leading-[24px] mb-[8px] mt-[34px]">{{ $t("social.name") }}</b>
                </div>
                <div class="socials flex items-center justify-between max-w-[100px]">
                  <a href="https://twitter.com/kinfolkvc" target="_blank" class="mr-[5px] w-[17.5px]">
                    <img src="../assets/twitter-logo.svg" alt="" width="100%">
                  </a>
                  <a href="https://www.instagram.com/kinfolkvc/" target="_blank" class="mr-[5px] w-[17.5px]">
                    <img src="../assets/instagram-logo.svg" alt="" width="100%">
                  </a>
                  <a href="https://www.linkedin.com/company/kinfolkvc" target="_blank" class="mr-[5px] w-[17.5px]">
                    <img src="../assets/linkedin-logo.svg" alt="" width="100%">
                  </a>
                </div>
              </div>
            </div>

          </div>
          <div class="footer">
            <p class="text-[12px] sm:text-[15px] text-[#828282] font-light leading-[23px] sm:leading-[32px] text-center">&copy; {{ $t("copyright") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'

export default {
  async mounted () {
    gsap.fromTo('.contact-circle-sprite', { opacity: 0, scaleX: 2, scaleY: 2 }, { opacity: 1, scaleX: 1, scaleY: 1, delay: 1, duration: 2 })
  }
}
</script>

<style scoped>
.contact {
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  display: none;
}
#contact-image-slider {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
}

.slide-in {
    animation: slide-in 1.5s forwards;
    -webkit-animation: slide-in 1.5s forwards;
}

@keyframes slide-in {
    100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
    100% { -webkit-transform: translateX(0%); }
}
</style>

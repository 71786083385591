<template>
  <div id="investment-strategy" class="mt-[40px] lg:mt-[0] mb-[80px]">
    <div class="subheading">
        <b class="text-[28px] block font-medium leading-[33px] mb-[24px]"
          >{{ $t("about.investmentStrategy.heading") }}</b
        >
      </div>
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.investmentStrategy.par") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.investmentStrategy.par2") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.investmentStrategy.par3") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.investmentStrategy.par4") }}
      </p>
      <br />
      <p class="text-[18px] leading-[32px]">
        {{ $t("about.investmentStrategy.par5") }}
      </p>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
</style>

<template>
  <div class="flex flex-col h-screen w-screen fixed top-0 left-0 items-center justify-center bg-white z-[1000]">
    <svg width="73" height="64" viewBox="0 0 73 64" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3549_7132)">
        <path d="M24.8477 0.21066C27.9289 0.338245 31.0069 0.280832 34.0849 0.166005C34.7738 0.140488 35.4628 0.0862642 36.1486 0.0192819C36.3942 -0.00623513 36.5281 0.0160923 36.4835 0.29678C36.4771 0.331866 36.4835 0.366952 36.4835 0.402038C36.4803 0.845397 36.6206 1.36531 36.4324 1.70979C36.2347 2.07341 35.6414 1.9522 35.2236 2.0447C31.5108 2.85168 28.5987 4.9058 26.2894 7.84345C21.2785 14.2132 16.2995 20.6084 11.2949 26.9844C11.0525 27.2907 11.2024 27.4374 11.3587 27.6543C16.6344 35.0383 21.9068 42.4223 27.1793 49.8063C28.8762 52.1825 30.5922 54.5429 32.2572 56.9383C34.0721 59.5538 36.5217 61.2284 39.587 62.0162C39.9123 62.0991 40.024 62.2203 39.9984 62.5457C39.9634 63.0018 40.1324 63.5919 39.9059 63.8757C39.689 64.1469 39.083 63.8821 38.6492 63.863C34.8982 63.6908 31.1472 63.6748 27.393 63.7035C25.0582 63.7195 22.7234 63.7737 20.395 63.9523C19.9516 63.9874 19.7379 63.9204 19.8049 63.4132C19.8591 63.0145 19.6869 62.4978 19.8814 62.2267C20.0856 61.9428 20.6374 62.0896 21.0329 62.0289C21.7474 61.9173 22.4682 61.8057 23.1221 61.474C23.9291 61.0657 24.1683 60.4915 23.8908 59.6303C23.7313 59.1391 23.4793 58.6958 23.1955 58.2652C18.6024 51.3405 14.0061 44.4158 9.40986 37.4943C8.68582 36.4034 7.89479 35.3636 7.02721 34.3748C5.60782 32.7577 3.81525 31.9316 1.69733 31.753C1.25716 31.7179 0.816993 31.6636 0.373634 31.6509C0.128032 31.6445 0.0451016 31.5839 0.0291534 31.3223C-0.0569667 29.8296 -0.0633458 29.8392 1.38475 29.7212C5.02412 29.4309 8.08617 27.9094 10.3763 25.093C13.8849 20.7838 17.3233 16.4172 20.6725 11.9804C21.7697 10.5259 22.7393 8.98534 23.4411 7.29165C23.7058 6.65372 23.9131 5.99666 23.945 5.29813C24.0056 3.98719 23.4442 3.05581 22.2449 2.51358C21.2275 2.05427 20.1462 1.88841 19.0426 1.86927C18.5482 1.8597 18.3504 1.74168 18.3759 1.1835C18.4301 -0.0572693 18.3919 -0.0285626 19.6454 0.0320405C21.3806 0.12454 23.1157 0.156436 24.8477 0.21066Z" fill="black"/>
        <path d="M56.8527 0.165909C60.6388 0.165909 64.4281 0.178668 68.2142 0.15953C69.6112 0.153151 71.0083 0.105306 72.4022 0.00642757C72.9157 -0.031848 72.9859 0.102117 72.9348 0.561424C72.6286 3.30451 72.4915 6.06035 72.399 8.81939C72.2905 12.0409 72.3639 15.2561 72.6063 18.468C72.6414 18.9146 72.5393 19.0772 72.0896 19.0071C71.8982 18.9784 71.7004 19.0007 71.5059 19.0007C70.482 19.0007 70.5075 18.9975 70.3927 17.9481C70.0705 14.9754 69.6336 12.025 68.8298 9.13516C67.7261 5.16088 65.0915 2.99511 61.0598 2.4146C59.532 2.19452 58.0009 2.07969 56.4604 2.09883C52.0682 2.15624 47.6793 2.00633 43.2904 1.99038C42.5312 1.98719 41.7657 1.96167 41.013 2.08288C40.7514 2.12434 40.6461 2.08607 40.6366 1.77348C40.5855 0.271167 40.5728 0.271167 42.0815 0.271167C47.0063 0.271167 51.9279 0.271167 56.8527 0.271167C56.8527 0.236081 56.8527 0.200995 56.8527 0.165909Z" fill="black"/>
        <path d="M46.3328 31.756C47.9435 31.756 49.5575 31.8103 51.1651 31.7401C52.8428 31.6667 54.444 31.2521 55.5859 29.8965C56.7597 28.5026 57.4263 26.8536 57.7357 25.0642C58.0036 23.4981 58.2492 21.9256 58.498 20.3531C58.5491 20.0246 58.6256 19.8077 59.053 19.8587C59.5761 19.9193 60.281 19.696 60.5872 19.964C60.903 20.2383 60.6287 20.9559 60.5872 21.4727C60.2428 25.8042 60.4118 30.1389 60.4309 34.4736C60.4469 37.9918 60.6606 41.5068 60.9381 45.0122C60.9732 45.4396 60.8839 45.6246 60.4182 45.5672C60.0514 45.5225 59.675 45.5353 59.305 45.564C58.935 45.5959 58.8425 45.4492 58.7947 45.0951C58.514 42.9931 58.262 40.888 57.7485 38.8243C57.5954 38.2055 57.3338 37.6282 57.0467 37.0604C55.8857 34.7607 53.905 33.7878 51.4458 33.6794C48.1222 33.5295 44.7922 33.6379 41.4654 33.6315C40.9997 33.6315 40.8689 33.5263 40.8817 33.051C40.9168 31.7752 40.8913 31.7752 42.1894 31.7752C43.5706 31.7752 44.9517 31.7752 46.3328 31.7752C46.3328 31.7656 46.3328 31.7592 46.3328 31.756Z" fill="black"/>
      </g>
      <defs>
        <clipPath id="clip0_3549_7132">
          <rect width="72.9501" height="64" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <!-- <h1 class="font-serif font-medium text-[18px] text-black leading-[23.99px] tracking-[0.6px] text-center mt-4 mb-8">Kinfolk Venture Capital</h1> -->
    <div class="pt-8">
      <!-- {{$route.path}} -->
      <div class="progress-container">
        <div class="progress"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    const progressbar = document.querySelector('.progress')

    const changeProgress = (progress) => {
      progressbar.style.width = `${progress}%`
    }

    setTimeout(() => changeProgress(40), 1000)
    setTimeout(() => changeProgress(80), 2000)
    // setTimeout(() => changeProgress(90), 4000)
    setTimeout(() => changeProgress(100), 3000)
    // setTimeout(() => changeProgress(100), 12000)
  }
}
</script>

<style scoped>

</style>

<template>
<div id="the-team" class="relative overflow-hidden">
    <div class="team-circle-sprite max-w-[736px] w-full max-h-[736px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] rounded-full top-[-530px] right-[-110px] lg:right-[130px] md:top-[-390px] opacity-0"></div>
    <div class="main-container flex flex-col md:max-w-[85%] max-w-[95%] mx-auto w-full relative">
      <div class="min-h-[100vh] max-h-screen relative">
        <div class="main md:pt-[130px] pt-[100px] px-[20px] mx-auto relative">
          <button @click="goBack" class="flex items-center font-medium md:text-[16px] text-[16px] mt-[20px] mb-[40px]">
            <span class="block mr-2">
              <svg width="20" height="20" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11 7L7 11L11 15" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1Z" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M15 10.998L7 10.998" stroke="black" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
            {{ $t("team.ctas.back") }}
          </button>
          <div class="max-h-[calc(100vh-190px)] pb-[32px] overflow-y-auto">
            <div class="mx-auto">
              <div class="flex flex-col lg:flex-row justify-center lg:justify-start pb-[50px]">

                <div class="left lg:w-[40%] w-full lg:pb-[0] pb-[16px]">
                  <template v-if="id === 'toni'">
                    <img class="h-auto w-full mx-auto lg:mx-0 max-h-[auto]" :src="this.teamMem[0].url" />
                  </template>
                  <template v-if="id === 'faithful'">
                    <img class="h-auto w-full mx-auto lg:mx-0 max-h-[auto]" :src="this.teamMem[1].url" />
                  </template>
                  <template v-if="id === 'chukwu'">
                    <img class="h-auto w-full mx-auto lg:mx-0 max-h-[auto]" :src="this.teamMem[2].url" />
                  </template>
                  <template v-if="id === 'ogooluwa'">
                    <img class="h-auto w-full mx-auto lg:mx-0 max-h-[auto]" :src="this.teamMem[3].url" />
                  </template>
                  <template v-if="id === 'jay'">
                    <img class="h-auto w-full mx-auto lg:mx-0 max-h-[auto]" :src="this.teamMem[4].url" />
                  </template>
                </div>

                <div class="lg:pl-[60px] pl-0 lg:w-[60%] w-full">
                  <template v-if="id === 'toni'">
                    <div class="flex flex-col">
                      <div class="head mb-[32px] text-left">
                        <h1 class="text-black md:text-[20px] text-[16px] font-bold mb-2">{{$t("team.toni.name", { par: this.teamMem[0].memberName})}}</h1>
                        <p class="text-greyText md:text-[16px] text-[14px] leading-[18.96px] font-Rubik">{{$t("team.toni.role", { role: this.teamMem[0].position })}}</p>
                      </div>
                      <div class="body">
                        <p class="text-black font-light">{{ $t("team.toni.par", { par: this.teamMem[0].paragraphOne}) }}</p><br />
                        <p class="text-black font-light">{{$t("team.toni.par2", { par2: this.teamMem[0].paragraphTwo})}}</p><br />
                        <p class="text-black font-light">{{$t("team.toni.par3", { par3: this.teamMem[0].paragraphThree})}}</p><br />
                        <p class="text-black font-light">{{$t("team.toni.par4", { par4: this.teamMem[0].paragraphFour})}}</p>
                      </div>
                    </div>
                  </template>
                  <template v-if="id === 'faithful'">
                    <div class="flex flex-col">
                      <div class="head mb-[40px] text-left">
                        <h1 class="text-black md:text-[20px] text-[16px] font-bold mb-2">{{$t("team.faithful.name", { par: this.teamMem[1].memberName})}}</h1>
                        <p class="text-greyText md:text-[16px] text-[14px] leading-[18.96px] font-Rubik">{{ $t("team.faithful.role", { role: this.teamMem[1].position }) }}</p>
                      </div>
                      <div class="body">
                        <p class="text-black font-medium italic">"{{$t("team.faithful.quote", { par: this.teamMem[1].personalQuote})}}"</p><br />
                        <p class="text-black font-light">{{$t("team.faithful.par", { par: this.teamMem[1].paragraphOne}) }}</p><br />
                        <p class="text-black font-light">{{ $t("team.faithful.par2", { par2: this.teamMem[1].paragraphTwo}) }}</p><br />
                        <p class="text-black font-light">{{ $t("team.faithful.par3", { par3: this.teamMem[1].paragraphThree}) }}</p><br />
                        <p class="text-black font-light">{{ $t("team.faithful.par4", { par4: this.teamMem[1].paragraphFour}) }}</p>
                      </div>
                    </div>
                  </template>
                  <template v-if="id === 'chukwu'">
                    <div class="flex flex-col">
                      <div class="head mb-[40px] text-left">
                        <h1 class="text-black md:text-[20px] text-[16px] font-bold mb-2">{{$t("team.chukwu.name", { par: this.teamMem[2].memberName})}}</h1>
                        <p class="text-greyText md:text-[16px] text-[14px] leading-[18.96px] font-Rubik">{{ $t("team.chukwu.role", { role: this.teamMem[2].position }) }}</p>
                      </div>
                      <div class="body">
                        <p class="text-black font-medium italic">"{{$t("team.chukwu.quote", { par: this.teamMem[2].personalQuote})}}"</p><br />
                        <p class="text-black font-light">{{$t("team.chukwu.par", { par: this.teamMem[2].paragraphOne}) }}</p><br />
                        <p class="text-black font-light">{{ $t("team.chukwu.par2", { par2: this.teamMem[2].paragraphTwo}) }}</p><br />
                        <p class="text-black font-light">{{ $t("team.chukwu.par3", { par3: this.teamMem[2].paragraphThree}) }}</p><br />
                        <p class="text-black font-light">{{ $t("team.chukwu.par4", { par4: this.teamMem[2].paragraphFour}) }}</p>
                      </div>
                    </div>
                  </template>
                  <template v-if="id === 'ogooluwa'">
                    <div class="flex flex-col">
                      <div class="head mb-[40px] text-left">
                        <h1 class="text-black md:text-[20px] text-[16px] font-bold mb-2">{{$t("team.ogo.name", { par: this.teamMem[3].memberName})}}</h1>
                        <p class="text-greyText md:text-[16px] text-[14px] leading-[18.96px] font-Rubik">{{ $t("team.ogo.role", { role: this.teamMem[3].position }) }}</p>
                      </div>
                      <div class="body">
                        <p class="text-black font-medium italic">"{{$t("team.ogo.quote", { par: this.teamMem[3].personalQuote})}}"</p><br />
                        <p class="text-black font-light">{{$t("team.ogo.par", { par: this.teamMem[3].paragraphOne}) }}</p><br />
                        <p class="text-black font-light">{{ $t("team.ogo.par2", { par2: this.teamMem[3].paragraphTwo}) }}</p><br />
                        <p class="text-black font-light">{{ $t("team.ogo.par3", { par3: this.teamMem[3].paragraphThree}) }}</p><br />
                        <p class="text-black font-light">{{ $t("team.ogo.par4", { par4: this.teamMem[3].paragraphFour}) }}</p>
                      </div>
                    </div>
                  </template>
                  <template v-if="id === 'jay'">
                    <div class="flex flex-col">
                      <div class="head mb-[40px] text-left">
                        <h1 class="text-black md:text-[20px] text-[16px] font-bold mb-2">{{$t("team.jay.name", { par: this.teamMem[4].memberName})}}</h1>
                        <p class="text-greyText md:text-[16px] text-[14px] leading-[18.96px] font-Rubik">{{ $t("team.jay.role", { role: this.teamMem[4].position }) }}</p>
                      </div>
                      <div class="body">
                        <!-- <p class="text-black font-medium italic">"{{this.teamMem[4].personalQuote}}"</p><br /> -->
                        <p class="text-black font-light">{{$t("team.jay.par", { par: this.teamMem[4].paragraphOne}) }}</p><br />
                        <p class="text-black font-light">{{ $t("team.jay.par2", { par2: this.teamMem[4].paragraphTwo}) }}</p><br />
                        <p class="text-black font-light">{{ $t("team.jay.par3", { par3: this.teamMem[4].paragraphThree}) }}</p><br />
                        <p class="text-black font-light">{{ $t("team.jay.par4", { par4: this.teamMem[4].paragraphFour}) }}</p>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { team } from '@/utils/team'
export default {
  props: ['id'], // id is passed from TeamPage as params and received as props
  data () {
    return {
      teamMem: team
    }
  },
  methods: {
    goBack () {
      return this.$router.push({ name: 'Team' })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <div id="companies" class="relative overflow-hidden">
    <div class="companies-circle-sprite max-w-[736px] w-full max-h-[736px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] border-opacity-25 rounded-full top-[-530px] right-[-110px] lg:right-[100px] md:top-[-390px] opacity-0 z-10"></div>
    <div class="companies-square-sprite max-w-[470px] w-full max-h-[470px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] border-opacity-25 transform rotate-[55deg] left-[300px] bottom-[-300px] opacity-0 z-10"></div>
    <div class="bg-black">
      <div id="scrollBarContainer" class="hidden lg:block bg-black">
        <div id="scrollBar" class="bg-white"></div>
      </div>
      <div id="company-scrollable" class="scrollable mx-auto xl:max-w-[2080px] max-w-[1200px] w-screen z-50">
        <div class="company-portfolio flex flex-col justify-center items-center pt-[100px] min-h-screen">
          <div class="heading max-w-[80%] px-[10px]">
            <h1 class="text-white text-[34px] md:text-[56px] leading-[40px] md:leading-[68px] text-center mb-[56px] font-normal">{{ $t("portfolio.heading") }}</h1>
            <p class="text-[#E5E6E6] text-xs md:text-lg leading-[26px] text-center font-light">
              {{ $t("portfolio.brief") }}
            </p>
          </div>
          <div class="company-grid grid grid-cols-3 xl:gap-[5rem] lg:gap-10 gap-2 w-full max-h-screen mt-[80px]">

            <a href="https://float.co/" target="_blank" class="z-50">
              <div class="company max-h-[200px] h-[50px] sm:h-[60px] lg:h-[80px] px-[20px] flex items-center justify-center">
                <img src="../assets/float.svg" alt="float" width="100%" class="md:w-[50%]">
              </div>
            </a>
            <a href="https://bento.africa" target="_blank" class="z-50">
              <div class="company max-h-[200px] h-[50px] sm:h-[60px] lg:h-[80px] px-[20px] flex items-center justify-center">
                <img src="../assets/bento.svg" alt="bento" width="100%" class="w-[80%] md:w-[50%]">
              </div>
            </a>
            <a href="https://dukka.com" target="_blank" class="z-50">
              <div class="company max-h-[200px] h-[50px] sm:h-[60px] lg:h-[80px] px-[20px] flex items-center justify-center">
                <img src="../assets/bukka.svg" alt="dukka" width="100%" class="w-[80%] md:w-[50%]">
              </div>
            </a>
            <a href="https://yassir.com" target="_blank" class="z-50">
              <div class="company max-h-[200px] h-[50px] sm:h-[60px] lg:h-[80px] px-[20px] flex items-center justify-center">
                <img src="../assets/Yassir.svg" alt="yassir" width="100%" class="w-[80%] md:w-[50%]">
              </div>
            </a>
            <a href="https://norebase.com" target="_blank" class="z-50">
              <div class="company max-h-[200px] h-[50px] sm:h-[60px] lg:h-[80px] px-[20px] flex items-center justify-center">
                <img src="../assets/norebase.svg" alt="float" width="100%" class="md:w-[50%]">
              </div>
            </a>
            <a href="https://majeursholdings.com" target="_blank">
              <div class="animate__companylogo company max-h-[200px] h-[50px] sm:h-[60px] lg:h-[80px] px-[20px] flex items-center justify-center">
                <img
                  src="../assets/majeurs-logo-white.webp"
                  alt="majeurs chesterfield"
                  class="max-h-[60%] md:w-auto w-full"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import store from '@/store'

export default {
  async mounted () {
    const progressBarContainer = document.querySelector('#scrollBarContainer')
    const progressBar = document.querySelector('#scrollBar')
    const scrollableDiv = document.querySelector('#company-scrollable')
    const mainContent = document.querySelector('.company-portfolio')
    let totalPageHeight = mainContent.offsetHeight - window.innerHeight
    let debounceResize

    scrollableDiv.addEventListener('scroll', () => {
      const newProgressHeight = scrollableDiv.scrollTop / totalPageHeight
      progressBar.style.transform = `scale(1.2,${newProgressHeight})`
    }, {
      capture: true,
      passive: true
    })

    scrollableDiv.addEventListener('resize', () => {
      clearTimeout(debounceResize)
      debounceResize = setTimeout(() => {
        totalPageHeight = scrollableDiv.offsetHeight - window.innerHeight
      }, 250)
    })

    progressBarContainer.addEventListener('click', (e) => {
      const newPageScroll = e.clientY / progressBarContainer.offsetHeight * totalPageHeight
      window.scrollTo({
        top: newPageScroll,
        behavior: 'smooth'
      })
    })

    gsap.fromTo('.companies-circle-sprite', { opacity: 0, scaleX: 2, scaleY: 2 }, { opacity: 1, scaleX: 1, scaleY: 1, delay: 1, duration: 2 })
    gsap.fromTo('.companies-square-sprite', { opacity: 0, scaleX: 2, scaleY: 2 }, { opacity: 1, scaleX: 1, scaleY: 1, delay: 1, duration: 2.1 })
  },
  // methods: {
  //   handleIsDark () {
  //     if ()
  //   }
  // },
  // computed: {
  //   isDark () {
  //     return store.getters.getDark
  //   }
  // },
  watch: {
    '$route.path': {
      immediate: true,
      handler () {
        store.dispatch('setDark', true)
      }
    }
  }

}
</script>

<style scoped>
#scrollBarContainer {
  position: fixed;
  left: 80px;
  top: 150px;
  z-index: 10;
  overflow: hidden;
}

#scrollBar {
  position: absolute;
  top: 0;
  will-change: transform, opacity;
  transform-origin: top center;
  transform: scale(1, 0);
}

#scrollBarContainer,
#scrollBar {
  right: 0;
  bottom: 0;
  width: 2px;
  height: 400px;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import AboutHome from '../views/AboutHome.vue'
import Philosophy from '../views/Philosophy.vue'
import InvestmentStrategy from '../views/InvestmentStrategy.vue'
import ImpactCommitments from '../views/ImpactCommitments.vue'
import Team from '../views/TheTeam.vue'
import MemberDetails from '../components/MemberDetails.vue'
// import Toni from
import Companies from '../views/Companies.vue'
import Stories from '../views/Stories.vue'
import Contacts from '../views/Contacts.vue'
import PitchUs from '../views/PitchUs.vue'
import Terms from '../views/Terms.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import AntiharassmentPolicy from '../views/AntiharassmentPolicy.vue'

import i18n from '../i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`
  },
  {
    path: '/:lang',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'about',
        component: About,
        children: [
          {
            path: '',
            name: 'About Home',
            component: AboutHome
          },
          {
            path: 'philosophy',
            name: 'Philosophy',
            component: Philosophy
          },
          {
            path: 'investment-strategy',
            name: 'Investment Strategy',
            component: InvestmentStrategy
          },
          {
            path: 'impact-commitments',
            name: 'Impact Commitments',
            component: ImpactCommitments
          }
        ]
      },
      {
        path: 'team',
        name: 'Team',
        component: Team
      },
      {
        path: 'team/:id',
        name: 'MemberDetails',
        component: MemberDetails,
        props: true
      },
      {
        path: 'companies',
        name: 'Companies',
        component: Companies
      },
      {
        path: 'stories',
        name: 'Stories',
        component: Stories
      },
      {
        path: 'contact',
        name: 'Contacts',
        component: Contacts
      },
      {
        path: 'join-us',
        name: 'Join Us',
        component: PitchUs
      },
      {
        path: 'terms-of-use',
        name: 'Terms of use',
        component: Terms
      },
      {
        path: 'privacy-policy',
        name: 'Privacy policy',
        component: PrivacyPolicy
      },
      {
        path: 'anti-harassment-policy',
        name: 'Anti-harassment policy',
        component: AntiharassmentPolicy
      }
    ]

  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

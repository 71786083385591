import { render, staticRenderFns } from "./InvestmentStrategy.vue?vue&type=template&id=687f163b&scoped=true"
import script from "./InvestmentStrategy.vue?vue&type=script&lang=js"
export * from "./InvestmentStrategy.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "687f163b",
  null
  
)

export default component.exports
<template>
  <div>
    <div class="hideMe">
      <div id="masterWrap">
        <div id="panelWrap">
          <section>
            <div
              class="circle-sprite max-w-[736px] w-full max-h-[736px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] rounded-full top-[-530px] right-[-110px] lg:right-[70%] md:top-[-70%] opacity-0 z-[1]"
            ></div>
            <div
              class="square-sprite max-w-[470px] w-full max-h-[470px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] transform rotate-[55deg] left-[300px] bottom-[-450px] opacity-0"
            ></div>
            <main
              class="mx-auto md:max-w-[85%] max-w-[95%] w-screen h-full px-[16px] mt-[150px] md:mt-0 flex items-center"
            >
              <div class="hero-container flex items-center w-full h-full">
                <div
                  class="left-side flex flex-col md:justify-center justify-start relative md:h-full h-[max-content] mx-auto mb-[0] md:mb-0 w-1/2"
                >
                  <div
                    class="large-kf-logo absolute max-h-[325px] w-[371px] top-[56%] md:top-[48%] lg:top-[45%] left-[25%] md:left-[125px] z-[0] opacity-0"
                  >
                    <img src="../assets/kinfolklogo.svg" alt="" class="w-[100%]" />
                  </div>
                  <h1
                    class="heading animate__hero leading-[40px] md:leading-[72px] text-center md:text-left font-normal text-[34px] md:text-[60px] mb-[24px] z-[30]"
                  >
                    {{ $t("home.slide1.heading") }}
                  </h1>
                  <p
                    class="subheading animate__hero font-light text-[14px] md:text-[20px] leading-[18px] md:leading-[26px] text-[#828282] text-center md:text-left mb-[20px] md:mb-[42px] max-w-[450px] mx-auto md:mx-0"
                  >
                    {{ $t("home.slide1.catchPhrase") }}
                  </p>
                  <CustomRouteLink location="about">
                    <button
                      class="more-btn relative w-[max-content] pb-[10px] mx-auto md:m-0 font-medium text-[12px] md:text-[17px] flex items-center opacity-0"
                    >
                      {{ $t("home.slide1.button") }}
                      <span
                        class="right-arrow w-[15px] h-[15px] md:w-[24px] md:h-[24px] ml-[12px]"
                      ></span>
                    </button>
                  </CustomRouteLink>
                </div>
                <div class="right-side mt-4 md:my-0 h-full relative bottom-0 w-1/2">
                  <div id="mapbox-canvas" class="absolute top-0 bottom-0 w-full h-full z-[50]"></div>
                  <!-- <div class="absolute top-[7px]" id="callout-manager"></div> -->
                </div>
              </div>
            </main>
          </section>
          <section class="justify-start md:justify-center">
            <div
              class="circle-sprite max-w-[736px] w-full max-h-[736px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-opacity-30 border-[1px] rounded-full top-[-390px] opacity-0"
            ></div>
            <div
              class="square-sprite max-w-[470px] w-full max-h-[470px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-opacity-30 border-[1px] transform rotate-[55deg] left-[300px] bottom-[-450px] opacity-0"
            ></div>
            <main
              class="mx-auto md:max-w-[85%] max-w-[95%] w-screen px-[16px] mt-[150px] md:mt-0"
            >
              <div class="grid grid-cols-1">
                <div class="left-side flex flex-col justify-center px-[16px] lg:px-[0]">
                  <h1
                    class="heading animate__hero leading-[40px] md:leading-[72px] text-left max-w-[275px] md:max-w-[476px] font-normal text-[34px] md:text-[60px] text-white mb-[48px]"
                  >
                    {{ $t("home.slide2.heading") }}
                  </h1>
                  <p
                    class="subheading animate__hero font-light text-[16px] lg:text-[32px] xl:text-[32px] leading-[26px] lg:leading-[44px] xl:leading-[44px] text-[#E5E6E6] text-left mb-[20px] lg:mb-[42px] xl:mb-[42px] max-w-[900px]"
                  >
                    {{ $t("home.slide2.catchPhrase") }}
                  </p>
                  <!-- <p class="subheading animate__hero font-light text-[16px] md:text-[32px] leading-[26px] md:leading-[44px] text-[#E5E6E6] text-left mb-[20px] md:mb-[42px] max-w-[900px]">
                    <span class="contents font-bold text-[#E5E6E6] text-[16px] md:text-[32px] leading-[26px]">Kinfolk Venture Capital</span> carefully considers
                    a multitude of sectors including agriculture, healthcare, education,
                      enterprise, real-estate, finance, consumer, logistics and transportation.
                  </p> -->
                  <CustomRouteLink location="about/investment-strategy">
                    <button
                      class="more-btn-white text-[#E5E6E6] relative w-[max-content] pb-[10px] m-0 font-medium text-[12px] md:text-[17px] flex items-center"
                    >
                      {{ $t("home.slide2.button") }}
                      <span
                        class="right-arrow-white w-[15px] h-[15px] md:w-[24px] md:h-[24px] ml-[12px]"
                      ></span>
                    </button>
                  </CustomRouteLink>
                </div>
              </div>
            </main>
          </section>
          <section>
            <div
              class="circle-sprite max-w-[736px] w-full max-h-[736px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-opacity-30 border-[1px] rounded-full top-[-390px] opacity-0"
            ></div>
            <div
              class="square-sprite max-w-[470px] w-full max-h-[470px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-opacity-30 border-[1px] transform rotate-[55deg] left-[300px] bottom-[-450px] opacity-0"
            ></div>
            <div class="w-full">
              <div
                class="min-h-[100vh] max-h-screen relative flex flex-col items-center justify-center"
              >
                <div class="main pt-[20px] mx-auto w-full md:max-w-[85%] max-w-[95%] relative">
                  <h1
                    class="font-serif font-normal text-[34px] md:text-[56px] leading-[40px] md:leading-[68px] text-center my-[40px] mt-[70px] md:mt-[70px] lg:mt-[70px]"
                  >
                    {{ $t("home.slide3.heading") }}
                  </h1>
                  <div
                    class="gallery w-full px-[10px] md:px-[20px] max-h-[calc(100vh-170px)] pb-0 md:overflow-y-[unset] overflow-y-auto"
                  >
                    <div
                      class="grid md:grid-cols-3 grid-cols-2 lg:gap-0 gap-[20px] xl:w-full pr-0 lg:scale-75"
                    >
                      <div
                        v-for="member in teamMembers.slice(
                          0,
                          `${windowWidth < 768 ? 2 : 3}`
                        )"
                        :key="member.id"
                        class="member-card px-0 md:px-[20px] py-[10px] md:py-[20px] relative overflow-visible rounded-[2px] grayscale hover:grayscale-0 transition-all h-[fit-content] w-full"
                        :class="[member.id > 3 && windowWidth < 769 ? 'hidden' : '']"
                      >
                        <router-link
                          :to="{
                            name: 'MemberDetails',
                            params: { id: member.path },
                          }"
                        >
                          <div
                            class="home team-member-image-container w-full relative overflow-hidden md:hover:rounded-[2px]"
                          >
                            <img
                              :src="member.url"
                              alt=""
                              width="100%"
                              height="100%"
                              class="w-full object-cover h-full"
                            />
                          </div>
                          <div class="details pt-[10px] md:pt-[20px]">
                            <h4
                              class="md:mb-[8px] mb-0 lg:text-[20px] md:text-[18px] text-[12px] text-center font-bold leading-[27px] sm:leading-[16px]"
                            >
                              <template v-if="member.memberName.includes('Toni')">
                                {{ $t("team.toni.name", { par: member.memberName}) }}
                              </template>
                              <template v-if="member.memberName.includes('Faithful')">
                                {{ $t("team.faithful.name", { par: member.memberName}) }}
                              </template>
                              <template v-if="member.memberName.includes('Chukwu')">
                                {{ $t("team.chukwu.name", { par: member.memberName}) }}
                              </template>
                              <template v-if="member.memberName.includes('Ogo')">
                                {{ $t("team.ogo.name", { par: member.memberName}) }}
                              </template>
                              <template v-if="member.memberName.includes('Jay')">
                                {{ $t("team.jay.name", { par: member.memberName}) }}
                              </template>
                            </h4>
                            <p
                              class="text-center lg:text-[18px] md:text-[14px] text-[10px]"
                            >
                              <template v-if="member.memberName.includes('Toni')">
                                {{ $t("team.toni.role", { par: member.position}) }}
                              </template>
                              <template v-if="member.memberName.includes('Faithful')">
                                {{ $t("team.faithful.role", { par: member.position}) }}
                              </template>
                              <template v-if="member.memberName.includes('Chukwu')">
                                {{ $t("team.chukwu.role", { par: member.position}) }}
                              </template>
                              <template v-if="member.memberName.includes('Ogo')">
                                {{ $t("team.ogo.role", { par: member.position}) }}
                              </template>
                              <template v-if="member.memberName.includes('Jay')">
                                {{ $t("team.jay.role", { par: member.position}) }}
                              </template>
                            </p>
                          </div>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center justify-center w-full lg:pt-0 pt-[30px] relative lg:bottom-7">
                    <CustomRouteLink location="team">
                      <button
                        class="more-btn relative w-[max-content] pb-[10px] md:m-0 font-medium text-[12px] md:text-[17px] flex items-center"
                      >
                        {{ $t("home.slide3.button") }}
                        <span
                          class="right-arrow w-[15px] h-[15px] md:w-[24px] md:h-[24px] ml-[12px]"
                        ></span>
                      </button>
                    </CustomRouteLink>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section class="justify-start md:justify-center overflow-hidden">
            <div
              class="circle-sprite max-w-[736px] w-full max-h-[736px] h-[425px] bg-[transparent] absolute border-solid border-[#e6e9e9] border-opacity-30 border-[1px] rounded-full md:top-[-390px] md:right-[-390px] right-[-200px] top-[-200px] opacity-0"
            ></div>
            <!-- <div
              class="square-sprite max-w-[470px] w-full max-h-[470px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-opacity-30 border-[1px] transform rotate-[55deg] left-[300px] bottom-[-450px] opacity-0"
            ></div> -->
            <main
              class="mx-auto md:max-w-[85%] max-w-[95%] w-screen px-[16px] mt-[100px] md:mt-0"
            >
              <div class="grid grid-cols-1">
                <div
                  class="company-portfolio flex flex-col justify-center items-center px-4 lg:px-0"
                >
                  <div class="heading md:max-w-[80%] md:px-[10px] md:mt-0 mt-6">
                    <h1
                      class="animate__hero text-white text-[34px] md:text-[56px] leading-[40px] md:leading-[68px] text-center mb-[56px] font-normal"
                    >
                      {{ $t("home.slide4.heading") }}
                    </h1>
                    <p
                      class="text-[#E5E6E6] text-xs md:text-lg leading-[26px] text-center font-light"
                    >
                      {{ $t("home.slide4.brief") }}
                    </p>
                  </div>
                  <div
                    class="company-grid grid grid-cols-3 xl:gap-[5rem] lg:gap-10 gap-2 w-full max-h-screen mt-[50px] md:mt-[70px] mb-[30px]"
                  >
                    <a href="https://float.co/" target="_blank">
                      <div
                        class="animate__companylogo company max-h-[200px] h-[50px] sm:h-[60px] lg:h-[80px] px-[20px] flex items-center justify-center"
                      >
                        <img
                          src="../assets/float.svg"
                          alt="float"
                          width="100%"
                          class="md:w-[50%]"
                        />
                      </div>
                    </a>
                    <a href="https://bento.africa" target="_blank">
                      <div
                        class="animate__companylogo company max-h-[200px] h-[50px] sm:h-[60px] lg:h-[80px] px-[20px] flex items-center justify-center"
                      >
                        <img
                          src="../assets/bento.svg"
                          alt="bento"
                          width="100%"
                          class="w-[80%] md:w-[50%]"
                        />
                      </div>
                    </a>
                    <a href="https://dukka.com" target="_blank">
                      <div
                        class="animate__companylogo company max-h-[200px] h-[50px] sm:h-[60px] lg:h-[80px] px-[20px] flex items-center justify-center"
                      >
                        <img
                          src="../assets/bukka.svg"
                          alt="dukka"
                          width="100%"
                          class="w-[80%] md:w-[50%]"
                        />
                      </div>
                    </a>
                    <a href="https://yassir.com" target="_blank">
                      <div
                        class="animate__companylogo company max-h-[200px] h-[50px] sm:h-[60px] lg:h-[80px] px-[20px] flex items-center justify-center"
                      >
                        <img
                          src="../assets/Yassir.svg"
                          alt="yassir"
                          width="100%"
                          class="w-[80%] md:w-[50%]"
                        />
                      </div>
                    </a>
                    <a href="https://norebase.com" target="_blank">
                      <div
                        class="animate__companylogo company max-h-[200px] h-[50px] sm:h-[60px] lg:h-[80px] px-[20px] flex items-center justify-center"
                      >
                        <img
                          src="../assets/norebase.svg"
                          alt="float"
                          width="100%"
                          class="md:w-[50%]"
                        />
                      </div>
                    </a>
                    <a href="https://majeursholdings.com" target="_blank">
                      <div class="animate__companylogo company max-h-[200px] h-[50px] sm:h-[60px] lg:h-[80px] px-[20px] flex items-center justify-center">
                        <img
                          src="../assets/majeurs-logo-white.webp"
                          alt="majeurs chesterfield"
                          class="max-h-[60%] md:w-auto w-full"
                        />
                      </div>
                    </a>

                    <!-- <div class="animate__companylogo company max-h-[200px] h-[50px] sm:h-[60px] md:h-[80px] px-[20px] flex items-center justify-center">
                      <img src="../assets/float.svg" alt="float" width="100%" class="md:w-[50%]">
                    </div>
                    <div class="animate__companylogo company max-h-[200px] h-[50px] sm:h-[60px] md:h-[80px] px-[20px] flex items-center justify-center">
                      <img src="../assets/tradedepot.svg" alt="tradedepot" width="100%" class="w-[80%] md:w-[50%]">
                    </div>
                    <div class="animate__companylogo company max-h-[200px] h-[50px] sm:h-[60px] md:h-[80px] px-[20px] flex items-center justify-center">
                      <img src="../assets/walletsafrica.svg" alt="walletsafrica" width="100%" class="w-[80%] md:w-[50%]">
                    </div>
                    <div class="animate__companylogo company max-h-[200px] h-[50px] sm:h-[60px] md:h-[80px] px-[20px] flex items-center justify-center">
                      <img src="../assets/bento.svg" alt="bento" width="100%" class="w-[80%] md:w-[50%]">
                    </div>
                    <div class="animate__companylogo company max-h-[200px] h-[50px] sm:h-[60px] md:h-[80px] px-[20px] flex items-center justify-center">
                      <img src="../assets/bukka.svg" alt="bukka" width="100%" class="w-[80%] md:w-[50%]">
                    </div>
                    <div class="animate__companylogo company max-h-[200px] h-[50px] sm:h-[60px] md:h-[80px] px-[20px] flex items-center justify-center">
                      <img src="../assets/Yassir.svg" alt="yassir" width="100%" class="w-[80%] md:w-[50%]">
                    </div> -->
                  </div>

                  <CustomRouteLink location="companies">
                    <button
                      class="more-btn-white text-[#E5E6E6] relative w-[max-content] pb-[10px] m-0 font-medium text-[12px] md:text-[17px] flex items-center opacity-0"
                    >
                      {{ $t("home.slide4.button") }}
                      <span
                        class="right-arrow-white w-[15px] h-[15px] md:w-[24px] md:h-[24px] ml-[12px]"
                      ></span>
                    </button>
                  </CustomRouteLink>
                </div>
              </div>
            </main>
          </section>
          <section class="justify-start sm:justify-center">
            <div
              class="circle-sprite max-w-[736px] w-full max-h-[736px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-opacity-30 border-[1px] rounded-full top-[-390px] opacity-0"
            ></div>
            <div
              class="square-sprite max-w-[470px] w-full max-h-[470px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-opacity-30 border-[1px] transform rotate-[55deg] left-[300px] bottom-[-450px] opacity-0"
            ></div>
            <main
              class="mx-auto md:max-w-[85%] max-w-[95%] w-screen px-[16px] pt-[100px] md:pt-0 flex h-full justify-start sm:justify-center"
            >
              <div class="grid grid-cols-1 md:justify-center w-full">
                <div class="flex md:flex-row flex-col justify-start items-center">
                  <div
                    class="flex flex-col justify-start md:justify-center px-[16px] md:w-[50%] w-[100%]"
                  >
                    <h1
                      class="pitch-heading max-w-[95%] font-normal text-[28px] md:text-[32px] lg:text-[57px] leading-[40px] md:leading-[45px] lg:leading-[64px] mb-3 md:mb-5 z-50"
                    >
                      {{ $t("home.slide5.heading") }}
                    </h1>
                    <p class="pb-[41px] md:pb-[58px] md:text-[18px]">
                      {{ $t("home.slide5.brief") }}
                    </p>
                    <a href="https://onyx.kinfolk.vc" target="_blank">
                      <button
                        class="more-btn relative w-[max-content] pb-[10px] md:m-0 font-medium text-[12px] md:text-[17px] flex items-center"
                      >
                        {{ $t("home.slide5.button") }}
                        <span
                          class="right-arrow w-[15px] h-[15px] md:w-[24px] md:h-[24px] ml-[12px]"
                        ></span>
                      </button>
                    </a>
                  </div>
                  <div
                    class="flex md:flex-row flex-col-reverse justify-center items-center mt-[50px] max-h-[600px] md:w-[50%] w-full"
                  >
                    <img
                      src="../assets/Pitch_Us.webp"
                      alt="pitch background"
                      width="100%"
                      class="min-w-[300px] w-[90%] h-[90%] md:mb-0 mb-8 z-[11]"
                    />
                  </div>
                </div>
              </div>
            </main>
          </section>
        </div>
      </div>
      <div class="dots z-[500]"></div>
    </div>
  </div>
</template>

<script>
// import Carousel from '@/components/Carousel'
// import CarouselSlide from '@/components/CarouselSlide'
import store from '@/store'
import { gsap } from 'gsap'
import { Draggable } from 'gsap/Draggable'
// import textureOrgin from '@/assets/images/clouds.png'
// import background from '@/assets/images/transparent.png'
// import diskBg from '@/assets/images/disk.png'
import { team } from '@/utils/team'
// import { MyGlobeKit } from '@/utils/globe'
import mapboxgl from 'mapbox-gl'
// import Loading from '@/components/Loading.vue'
import CustomRouteLink from '../components/customRouteLink.vue'
gsap.registerPlugin(Draggable)
export default {
  components: {
    // Loading,
    CustomRouteLink
  },
  data () {
    return {
      teamMembers: team,
      visibleSlide: 0,
      slideAmount: 2,
      direction: 'left',
      windowWidth: window.innerWidth,
      isLoading: false
    }
  },
  computed: {
    loadedVal () {
      const d = store.getters.getLoaded
      console.log(d)
      return store.getters.getLoaded
    },
    slidesLength () {
      return this.slideAmount
    }
  },
  methods: {
    checkSupport () {
      try {
        if (
          typeof WebAssembly === 'object' &&
          typeof WebAssembly.instantiate === 'function'
        ) {
          const module = new WebAssembly.Module(
            Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00)
          )
          if (module instanceof WebAssembly.Module) {
            return true
          }
        }
        // eslint-disable-next-line no-empty
      } catch (e) {}
      return false
    },
    nextSlide () {
      if (this.visibleSlide >= this.slideAmount - 1) {
        this.visibleSlide = 0
      } else {
        this.visibleSlide++
      }
      this.direction = 'left'
    },
    previousSlide () {
      if (this.visibleSlide <= 0) {
        this.visibleSlide = this.slideAmount - 1
      } else {
        this.visibleSlide--
      }
      this.direction = 'right'
    },
    animateGlobe () {
      const canvas = document.getElementById('mapbox-canvas')
      gsap.fromTo(canvas, { autoAlpha: 0 }, { autoAlpha: 1, duration: 1, delay: 1.5 })
    }
  },
  mounted () {
    // const canvas = document.getElementById('globekit-canvas')
    const slides = document.querySelectorAll('section')
    const container = document.querySelector('#panelWrap')
    const dots = document.querySelector('.dots')
    const circleSprite = document.querySelectorAll('.circle-sprite')
    const squareSprite = document.querySelectorAll('.square-sprite')
    const whiteMoreBtn = document.querySelectorAll('.more-btn-white')
    const regularMoreBtn = document.querySelectorAll('.more-btn')
    const homeStaggerContents = document.querySelectorAll('.animate__hero')
    const animatedLogos = document.querySelectorAll('.animate__companylogo')
    const animatedLargeLogo = document.querySelectorAll('.large-kf-logo')

    this.animateGlobe()
    setTimeout(() => {
      this.isLoading = false
    }, 3000)
    const d = store.getters.getLoaded
    console.log(d)
    setTimeout(() => {
      this.$store.dispatch('setLoaded')
    }, 3000)
    if (this.windowWidth === '') {
      window.addEventListener('resize', () => {
        this.windowWidth = window.innerWidth
      })
    }
    if (!this.checkSupport()) {
      this.$el.classList.add('fallback')
      return
    }

    // TO MAKE THE MAP APPEAR YOU MUST
    // ADD YOUR ACCESS TOKEN FROM
    // https://account.mapbox.com
    mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_ACCESS_TOKEN
    const map = new mapboxgl.Map({
      container: 'mapbox-canvas',
      // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
      style: process.env.VUE_APP_MAPBOX_STYLE_URL,
      // style: "mapbox://styles/mapbox/satellite-streets-v11",
      zoom: `${this.windowWidth > 1024 ? 1.5 : this.windowWidth < 600 ? 1.0 : 1.7}`,
      center: [30, 0],
      projection: 'globe',
      attributionControl: false
    })

    map.on('style.load', () => {
      // Set the default atmosphere style
      // map.setFog({
      //   color: 'rgb(186, 210, 235)', // Lower atmosphere
      //   'high-color': 'rgb(36, 92, 223)', // Upper atmosphere
      //   'horizon-blend': 0.02, // Atmosphere thickness (default 0.2 at low zooms)
      //   'space-color': 'rgb(11, 11, 25)', // Background color
      //   'star-intensity': 0.6 // Background star brightness (default 0.35 at low zoooms )
      // });
    })

    // The following values can be changed to control rotation speed:

    // At low zooms, complete a revolution every two minutes.
    const secondsPerRevolution = 120
    // Above zoom level 5, do not rotate.
    const maxSpinZoom = 5
    // Rotate at intermediate speeds between zoom levels 3 and 5.
    const slowSpinZoom = 3

    let userInteracting = false
    const spinEnabled = true

    function spinGlobe () {
      const zoom = map.getZoom()
      if (spinEnabled && !userInteracting && zoom < maxSpinZoom) {
        let distancePerSecond = 360 / secondsPerRevolution
        if (zoom > slowSpinZoom) {
          // Slow spinning at higher zooms
          const zoomDif = (maxSpinZoom - zoom) / (maxSpinZoom - slowSpinZoom)
          distancePerSecond *= zoomDif
        }
        const center = map.getCenter()
        center.lng -= distancePerSecond
        // Smoothly animate the map over one second.
        // When this animation is complete, it calls a 'moveend' event.
        map.easeTo({ center, duration: 1000, easing: (n) => n })
      }
    }

    // Pause spinning on interaction
    map.on('mousedown', () => {
      userInteracting = true
    })

    // Restart spinning the globe when interaction is complete
    map.on('mouseup', () => {
      userInteracting = false
      spinGlobe()
    })

    // These events account for cases where the mouse has moved
    // off the map, so 'mouseup' will not be fired.
    map.on('dragend', () => {
      userInteracting = false
      spinGlobe()
    })
    map.on('pitchend', () => {
      userInteracting = false
      spinGlobe()
    })
    map.on('rotateend', () => {
      userInteracting = false
      spinGlobe()
    })

    // When animation is complete, start spinning if there is no ongoing interaction
    map.on('moveend', () => {
      spinGlobe()
    })

    map.scrollZoom.disable()
    // When the map is clicked
    map.on('click', (event) => {
      // If the user clicked on one of your markers, get its information.
      const features = map.queryRenderedFeatures(event.point, {
        layers: ['features-2olrly'] // Your layer name
      })
      if (!features.length) {
        return
      }
      const feature = features[0]
      // Create a popup, specify its options  and properties, and add it to the map.
      // eslint-disable-next-line no-unused-vars
      new mapboxgl.Popup({ offset: [0, 15] })
        .setLngLat(feature.geometry.coordinates)
        .setHTML(
          `<div class="tour-callout">
              <div class="callout-container">
                <h3 style="font-family: Rubik">${feature.properties.industry_type}</h3>
                <div class="text-container">
                  <h4 style="font-family: Rubik">Location: </h4>
                  <h5 style="font-family: Rubik"> ${feature.properties.address}</h5>
                </div>
                <div class="text-container">
                  <h4 style="font-family: Rubik">Business Model: </h4>
                  <h5 style="font-family: Rubik"> ${feature.properties.business_model}</h5>
                </div>
              </div>
            </div>`
        ).addTo(map)
    })
    spinGlobe()

    // const { GlobeKitView, PointGlobe, Points, Atmosphere, Background, GKUtils } = await import('@/lib/globekit/globekit.esm.js')

    // eslint-disable-next-line no-unused-vars
    // const gk = new MyGlobeKit(canvas)
    // const apiKey = 'gk_c3a5e05ecc38e5f9e984ba54598cb17c2a4277e8ee72849c4e55a4eedc4b18f33710febfa273ca71a971c6270ad0f6ac1b45961f6e5b837395447ed2b5e40633'
    // const textures = {
    //   noise: textureOrgin
    // }

    // const generateRandomGeoJson = (count) => {
    //   const geojson = {
    //     type: 'FeatureCollection',
    //     features: []
    //   }

    //   for (let i = 0; i < count; i += 1) {
    //     const feature = {
    //       type: 'Feature',
    //       properties: {

    //       },
    //       geometry: {
    //         type: 'Point',
    //         coordinates: []
    //       }
    //     }

    //     const lat = Math.random() * 180 - 90
    //     const lon = Math.random() * 360 - 180
    //     // Geojson records longitude first, this is a common gotcha
    //     feature.geometry.coordinates = [lon, lat]

    //     // Geojson properties are the catchall for any data values
    //     feature.properties.mythicalCreatureSightings = Math.floor(Math.random() * 30)

    //     geojson.features.push(feature)
    //   }

    //   return geojson
    // }

    // // Generate some random Geojson
    // const randomGeojson = generateRandomGeoJson(200)

    // this.gkview = new GlobeKitView(canvas, {
    //   apiKey,
    //   wasmPath: 'https://fuselogo.s3.eu-central-1.amazonaws.com/gkweb_bg.wasm',
    //   attributes: {
    //     alpha: true, antialias: true
    //   }
    // })

    // this.gkview.onSelection = (list) => {
    //   // Uncomment this line to see the list object
    //   // console.log(list);

    //   // Iterate over the drawables that reported a selection
    //   list.drawables.forEach((el) => {
    //     // This gets run if the points object is selected
    //     if (el.obj.id === this.pointglobe.id) {
    //       // Check that selection is valid
    //       if (el.selection !== undefined) {
    //         // Create a ripple at the location with duration of 3 seconds
    //         this.pointglobe.rippleAtLocation(el.selection.lat, el.selection.lon, 3000)
    //       }
    //     } else if (el.obj.id === this.points.id) {
    //       if (el.selection !== undefined) {
    //         // Do something with selected point
    //       }
    //     }
    //   })
    // }

    // this.background = new Background(background)
    // this.gkview.addDrawable(this.background)

    // // **********************************************************************
    // //                   ATMOSPHERES
    // // **********************************************************************
    // this.atmosphere = new Atmosphere({
    //   texture: diskBg
    // })
    // this.atmosphere.nScale = 1.02
    // this.gkview.addDrawable(this.atmosphere)

    // fetch('https://fuselogo.s3.eu-central-1.amazonaws.com/pointglobe.bin')
    //   .then((res) => res.arrayBuffer())
    //   .then((data) => {
    //     // Some pointglobe settings
    //     const pointglobeParams = {
    //       pointSize: 0.004,
    //       randomPointSizeVariance: 0.004,
    //       randomPointSizeRatio: 0.1,
    //       minPointAlpha: 0.0,
    //       minPointSize: 0.006,
    //       color: '#b5b5b5'
    //     }
    //     this.pointglobe = new PointGlobe(textures, data, pointglobeParams)
    //     this.pointglobe.setInteractive(true, true, false)
    //   })
    //   .then(() => {
    //     // Add the drawable, start drawing when it finishes
    //     this.gkview.addDrawable(this.pointglobe, () => {
    //       this.gkview.startDrawing()
    //     })

    //     // **********************************************************************
    //     //                   POINTS
    //     // **********************************************************************
    //     this.points = new Points()

    //     // Transforms allow you to specify how data influences geometry
    //     this.points.transform = (element, point) => {
    //       // We are lerping colors based on a property from the geojson
    //       point.color = GKUtils.lerpColor('#228B22', '#0DDf00', element.properties.mythicalCreatureSightings / 30)
    //       // Transforms have to return the point object
    //       return point
    //     }
    //     // Add geojson to the points object now that the transform is defined
    //     this.points.addGeojson(randomGeojson)
    //     this.points.setInteractive(true, true, false)
    //     this.gkview.addDrawable(this.points)
    //   })

    const colorArray = ['#FFFFFF', '#000000', '#FFFFFF', '#000000', '#FFFFFF']
    const dur = 2
    let offsets = []
    let oldSlide = 0
    let activeSlide = 0
    // console.log(dots)
    const navDots = []
    let iw = window.innerWidth
    // const mouseAnim = gsap.timeline({ repeat: -1, repeatDelay: 1 })
    // const handAnim = gsap.timeline({ repeat: -1, repeatDelay: 1 })
    // const cursorAnim = gsap.timeline({ repeat: -1, repeatDelay: 1 })
    // const arrowAnim = gsap.timeline({ repeat: -1, repeatDelay: 1 })

    // document.querySelector('#leftArrow').addEventListener('click', slideAnim)
    // document.querySelector('#rightArrow').addEventListener('click', slideAnim)

    // set slides background colors and create the nav dots
    for (let i = 0; i < slides.length; i++) {
      gsap.set(slides[i], { backgroundColor: colorArray[i] })
      const newDot = document.createElement('div')
      newDot.className = 'dot'
      newDot.index = i
      // newDot.style.backgroundColor = '#fff'
      navDots.push(newDot)
      // console.log(navDots)
      newDot.addEventListener('click', slideAnim)
      dots.appendChild(newDot)
      document.querySelectorAll('.dot')[activeSlide].style.backgroundColor = '#000'
    }

    // icon animations for slide 1
    // mouseAnim.fromTo(
    //   '#mouseRings circle',
    //   { attr: { r: 10 } },
    //   { attr: { r: 40 }, duration: 0.8, stagger: 0.25 }
    // )
    // mouseAnim.fromTo(
    //   '#mouseRings circle',
    //   { opacity: 0 },
    //   { opacity: 1, duration: 0.4, stagger: 0.25 },
    //   0
    // )
    // mouseAnim.fromTo(
    //   '#mouseRings circle',
    //   { opacity: 1 },
    //   { opacity: 0, duration: 0.4, stagger: 0.25 },
    //   0.4
    // )

    // handAnim.to('#hand', { duration: 0.75, rotation: -10, transformOrigin: 'center bottom' })
    // handAnim.to('#hand', { duration: 0.5, rotation: 14, ease: 'power3.inOut' })
    // handAnim.to('#hand', { duration: 1, rotation: 0, transformOrigin: 'center bottom' })

    // cursorAnim.to('#cursor', { duration: 0.25, x: -22 })
    // cursorAnim.to(
    //   '#iconCircles circle',
    //   0.5,
    //   { duration: 0.5, attr: { r: 6 }, stagger: 0.15 },
    //   'expand'
    // )
    // cursorAnim.to('#cursor', { duration: 1.1, x: 40 }, 'expand')
    // cursorAnim.to('#cursor', { duration: 0.75, x: 0 }, 'contract')
    // cursorAnim.to('#iconCircles circle', { duration: 0.5, attr: { r: 4 } }, 'contract')

    // arrowAnim.to('#caret', {
    //   duration: 0.5,
    //   attr: { points: '60 30, 35 50, 60 70' },
    //   repeat: 3,
    //   yoyo: true,
    //   ease: 'power2.inOut',
    //   repeatDelay: 0.25
    // })

    // get elements positioned
    gsap.set('.dots', { xPercent: -50 })
    // gsap.set('.arrow', { yPercent: -50 })
    // gsap.set('.title', { y: 30 })

    // lower screen animation with nav dots and rotating titles
    const dotAnim = gsap.timeline({ paused: true })
    dotAnim.to(
      '.dot',
      {
        stagger: { each: 1, yoyo: true, repeat: 1 },
        // scale: 2.1,
        rotation: 0.1,
        ease: 'none'
      },
      0.5
    )
    // dotAnim.to(
    //   '.title',
    //   slides.length + 1,
    //   { y: -(slides.length * 30), rotation: 0.01, ease: 'none' },
    //   0
    // )
    dotAnim.time(1.5)

    // // make the whole thing draggable
    // const dragMe = Draggable.create(container, {
    //   type: 'x',
    //   edgeResistance: 1,
    //   snap: offsets,
    //   inertia: true,
    //   bounds: '#masterWrap',
    //   onDrag: tweenDot,
    //   onThrowUpdate: tweenDot,
    //   onDragEnd: slideAnim,
    //   allowNativeTouchScrolling: false,
    //   zIndexBoost: false
    // })

    // dragMe[0].id = 'dragger'
    sizeIt()

    // main action check which of the 4 types of interaction called the function
    function slideAnim (e) {
      const allDots = document.querySelectorAll('.dot')
      console.log(allDots)
      if (activeSlide < 0) {
        activeSlide = 0
        allDots[activeSlide].style.backgroundColor = '#000'
        return
      }
      if (activeSlide > slides.length - 1) {
        activeSlide = slides.length - 1
        allDots[activeSlide].style.backgroundColor = '#000'
        return
      }
      oldSlide = activeSlide
      // console.log(activeSlide)
      // document.querySelectorAll('.dot')[activeSlide].style.backgroundColor = '#000'
      // dragging the panels
      // if (this.id === 'dragger') {
      //   activeSlide = offsets.indexOf(this.endX)
      // } else {
      if (gsap.isTweening(container)) {
        store.dispatch('setTweening', true)
        return
      }

      if (this.className === 'dot') {
        activeSlide = this.index
        gsap.fromTo(
          circleSprite,
          { opacity: 0, scaleX: 2, scaleY: 2 },
          { opacity: 1, scaleX: 1, scaleY: 1, delay: 0.5, duration: 2 }
        )
        gsap.fromTo(
          squareSprite,
          { opacity: 0, scaleX: 2, scaleY: 2 },
          { opacity: 1, scaleX: 1, scaleY: 1, delay: 0.5, duration: 2.1 }
        )
        gsap.fromTo(whiteMoreBtn, { opacity: 0 }, { opacity: 1, delay: 3, duration: 1 })
        gsap.fromTo(
          regularMoreBtn,
          { opacity: 0 },
          { opacity: 1, delay: 3, duration: 1 }
        )
        gsap.fromTo(
          homeStaggerContents,
          { opacity: 0, y: -150 },
          { duration: 0.6, opacity: 1, y: '', stagger: 0.2, delay: 1.5 }
        )
        gsap.fromTo(
          animatedLogos,
          { opacity: 0 },
          { duration: 0.6, opacity: 1, stagger: 0.2, delay: 2 }
        )
        if (activeSlide === 1 || activeSlide === 3) {
          store.dispatch('setDark', true)
          Array.from(allDots).forEach(function (val) {
            val.style.backgroundColor = 'rgba(244, 245, 245, 0.24)'
          })
          allDots[activeSlide].style.backgroundColor = '#fff'
        } else if (activeSlide === 2) {
          store.dispatch('setDark', false)
          Array.from(allDots).forEach(function (val) {
            val.style.backgroundColor = '#E5E6E6'
          })
          allDots[activeSlide].style.backgroundColor = '#000'
        } else {
          store.dispatch('setDark', false)
          gsap.fromTo(
            animatedLargeLogo,
            { opacity: 0, scaleX: 1.2, scaleY: 1.2 },
            { opacity: 1, scaleX: 1, scaleY: 1, delay: 1, duration: 2.1 }
          )
          gsap.fromTo(
            regularMoreBtn,
            { opacity: 0 },
            { opacity: 1, delay: 3, duration: 1 }
          )
          gsap.fromTo(
            homeStaggerContents,
            { opacity: 0, y: -150 },
            { duration: 0.6, delay: 1.5, opacity: 1, y: '', stagger: 0.2 }
          )
          gsap.fromTo(
            animatedLogos,
            { opacity: 0 },
            { duration: 0.6, opacity: 1, stagger: 0.2, delay: 2 }
          )
          Array.from(allDots).forEach(function (val) {
            val.style.backgroundColor = '#E5E6E6'
          })
          allDots[activeSlide].style.backgroundColor = '#000'
        }
        // scrollwheel
      } else {
        activeSlide = e.deltaY > 0 ? (activeSlide += 1) : (activeSlide -= 1)
        gsap.fromTo(
          circleSprite,
          { opacity: 0, scaleX: 2, scaleY: 2 },
          { opacity: 1, scaleX: 1, scaleY: 1, delay: 0.5, duration: 2 }
        )
        gsap.fromTo(
          squareSprite,
          { opacity: 0, scaleX: 2, scaleY: 2 },
          { opacity: 1, scaleX: 1, scaleY: 1, delay: 0.5, duration: 2.1 }
        )
        gsap.fromTo(whiteMoreBtn, { opacity: 0 }, { opacity: 1, delay: 3, duration: 1 })
        gsap.fromTo(
          regularMoreBtn,
          { opacity: 0 },
          { opacity: 1, delay: 3, duration: 1 }
        )
        gsap.fromTo(
          homeStaggerContents,
          { opacity: 0, y: -150 },
          { duration: 0.6, delay: 1.5, opacity: 1, y: '', stagger: 0.2 }
        )
        gsap.fromTo(
          animatedLogos,
          { opacity: 0 },
          { duration: 0.6, opacity: 1, stagger: 0.2, delay: 2 }
        )
        if (activeSlide === 1 || activeSlide === 3) {
          store.dispatch('setDark', true)
          Array.from(document.querySelectorAll('.dot')).forEach(function (val) {
            val.style.backgroundColor = 'rgba(244, 245, 245, 0.24)'
          })
          document.querySelectorAll('.dot')[activeSlide].style.backgroundColor = '#fff'
        } else if (activeSlide === 2) {
          store.dispatch('setDark', false)
          Array.from(document.querySelectorAll('.dot')).forEach(function (val) {
            val.style.backgroundColor = '#E5E6E6'
          })
          document.querySelectorAll('.dot')[activeSlide].style.backgroundColor = '#000'
        } else {
          store.dispatch('setDark', false)
          gsap.fromTo(
            animatedLargeLogo,
            { opacity: 0, scaleX: 1.2, scaleY: 1.2 },
            { opacity: 1, scaleX: 1, scaleY: 1, delay: 1, duration: 2.1 }
          )
          gsap.fromTo(
            regularMoreBtn,
            { opacity: 0 },
            { opacity: 1, delay: 3, duration: 1 }
          )
          gsap.fromTo(
            homeStaggerContents,
            { opacity: 0, y: -150 },
            { duration: 0.6, delay: 1.5, opacity: 1, y: '', stagger: 0.2 }
          )
          gsap.fromTo(
            animatedLogos,
            { opacity: 0 },
            { duration: 0.6, opacity: 1, stagger: 0.4, delay: 2 }
          )
          Array.from(document.querySelectorAll('.dot')).forEach(function (val) {
            val.style.backgroundColor = '#E5E6E6'
          })
          if (activeSlide < 0) {
            document.querySelectorAll('.dot')[0].style.backgroundColor = '#000'
          } else if (activeSlide > slides.length - 1) {
            document.querySelectorAll('.dot')[slides.length - 1].style.backgroundColor =
              '#000'
          } else {
            document.querySelectorAll('.dot')[activeSlide].style.backgroundColor = '#000'
          }
          // document.querySelectorAll('.dot')[activeSlide].style.backgroundColor = '#000'
        }
      }

      // make sure we're not past the end or beginning slide
      if (activeSlide > slides.length - 1) {
        activeSlide = slides.length - 1
      }
      if (activeSlide < 0) {
        activeSlide = 0
      }
      // activeSlide = activeSlide < 0 ? 0 : activeSlide
      // activeSlide = activeSlide > slides.length - 1 ? slides.length - 1 : activeSlide
      if (oldSlide === activeSlide) {
        return
      }
      // if we're dragging we don't animate the container
      if (this.id !== 'dragger' && activeSlide > -1) {
        gsap.to(container, dur, { x: offsets[activeSlide] })
      }
    }

    // update the draggable element snap points
    function sizeIt () {
      offsets = []
      iw = window.innerWidth
      gsap.set('#panelWrap', { width: slides.length * iw })
      gsap.set(slides, { width: iw })
      for (let i = 0; i < slides.length; i++) {
        offsets.push(-slides[i].offsetLeft)
      }
      gsap.set(container, { x: offsets[activeSlide] })
      // dragMe[0].vars.snap = offsets
    }

    gsap.set('.hideMe', { opacity: 1 })
    window.addEventListener('wheel', slideAnim, { passive: true })
    window.addEventListener('resize', sizeIt)

    // update dot animation when dragger moves
    // function tweenDot () {
    //   gsap.set(dotAnim, {
    //     time: Math.abs(gsap.getProperty(container, 'x') / iw) + 1.5
    //   })
    // }

    gsap.fromTo(
      circleSprite,
      { opacity: 0, scaleX: 2, scaleY: 2 },
      { opacity: 1, scaleX: 1, scaleY: 1, delay: 1, duration: 2 }
    )
    gsap.fromTo(
      squareSprite,
      { opacity: 0, scaleX: 2, scaleY: 2.8 },
      { opacity: 1, scaleX: 1, scaleY: 1, delay: 1, duration: 2.1 }
    )
    // gsap.fromTo(animatedLargeLogo, { opacity: 0 }, { opacity: 1, delay: 1, duration: 1.8 })
    gsap.fromTo(
      animatedLargeLogo,
      { opacity: 0, scaleX: 1.2, scaleY: 1.2 },
      { opacity: 1, scaleX: 1, scaleY: 1, delay: 1, duration: 2.1 }
    )
    gsap.fromTo(regularMoreBtn, { opacity: 0 }, { opacity: 1, delay: 2, duration: 1.5 })
    gsap.from(homeStaggerContents, {
      duration: 0.6,
      opacity: 0,
      y: -150,
      stagger: 0.2,
      delay: 1.5
    })
  },
  created () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
      window.location.reload()
    })
  },
  destroyed () {
    window.removeEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  beforeUnmount () {
    console.log('unmounted')
  }
}
</script>

<style>

@media only screen and (min-width: 1024px) and (max-width: 1440px) {

  .hero-container {
    flex-direction: row;
  }
}
@media only screen and (max-width: 1024px) {

  .hero-container {
    flex-direction: column;
  }
  .hero-container .left-side, .hero-container .right-side {
    width: 100%!important;
  }
}
@media only screen and (max-width: 768px) and (min-width: 640px) {

  .hero-container {
    flex-direction: column;
  }
}

#masterWrap {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

#panelWrap {
  height: 100%;
}

section {
  height: 100%;
  display: flex;
  align-content: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  position: relative;
  float: left;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: relative;
  background-color: #e5e6e6;
  margin: 12px 8px;
  cursor: pointer;
  float: left;
}

.dots {
  position: absolute;
  z-index: 100;
  right: 50px;
  bottom: 16px;
}

#leftArrow {
  left: 12px;
}

#rightArrow {
  right: 12px;
}

.arrow {
  position: absolute;
  z-index: 100;
  top: 50%;
  cursor: pointer;
  opacity: 0.75;
  width: 40px;
  height: 40px;
  stroke-width: 8px;
  stroke: white;
  fill: transparent;
}

span {
  display: block;
  font-size: 0.75em;
  font-weight: 400;
}

.hideMe {
  opacity: 0;
}
.iconWrap {
  width: 300px;
  margin-top: 30px;
}

@media (min-width: 700px) {
  .iconWrap {
    width: 600px;
  }
}
.icon {
  text-align: center;
  width: 150px;
  float: left;
}

.icon svg {
  width: 50px;
  height: 50px;
  margin: 6px 0;
  overflow: visible;
}

@media (max-width: 480px) {
  section img {
    max-width: 200px;
  }
  /* p {
    max-width: 180px;
  } */
}
.member-card .surface {
  transition: all 1s cubic-bezier(0.42, 0.01, 0, 1.04);
}

.member-card:hover .surface {
  opacity: 0.6;
}

@media screen and (max-width: 767px) {
  .member-card:hover .surface {
    display: block;
    opacity: 1 !important;
    z-index: 100;
  }
}

@media screen and (min-width: 2000px) {
  .pitch-heading {
    max-width: 60%;
    line-height: 80px;
  }
}
@media screen and (min-width: 3000px) {
  .pitch-heading {
    max-width: 50%;
    line-height: 80px;
  }
}
</style>

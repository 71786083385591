<!-- This component serves as a hook to append i18n.locale so you don't have to keep repeating <router-link :to="`/${$i18n.locale}/${path}`
  Usage:
    Register this component where you need router-link and use it the same way -
    instead of <router-link to="">{{ label here }}</router-link>
    you will make use of the location prop like this <ComponentName location="">{{ label here }}</ComponentName>
-->
<template>
  <router-link :to="`/${$i18n.locale}/${location}`" :class="styles">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: 'CustomRouteLink',
  props: ['location', 'styles']
}
</script>

<style scoped></style>

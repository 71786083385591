<template>
  <div
    class="relative mx-auto md:max-w-[85%] max-w-[95%] w-screen px-[16px] pb-[50px] mt-[150px] md:mt-0"
  >
    <div class="flex flex-col mt-[150px] mb-[50px]">
      <div class="heading mb-[60px]">
        <h1 class="lg:text-[56px] md:text-[40px] text-[34px] leading-[68px] text-center">
          {{ $t("legal.terms.heading") }}
        </h1>
      </div>
      <div class="content">
        <p v-html="$t('legal.terms.brief')"></p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1
          class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium"
        >
          {{ $t("legal.terms.sub1.heading") }}
        </h1>
      </div>
      <div class="content">
        <p>
          {{ $t("legal.terms.sub1.par") }}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1
          class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium"
        >
          {{ $t("legal.terms.sub2.heading") }}
        </h1>
      </div>
      <div class="content">
        <p>
          {{ $t("legal.terms.sub2.par") }}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1
          class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium"
        >
          {{ $t("legal.terms.sub3.heading") }}
        </h1>
      </div>
      <div class="content">
        <p>
          {{ $t("legal.terms.sub3.par") }}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1
          class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium"
        >
          {{ $t("legal.terms.sub4.heading") }}
        </h1>
      </div>
      <div class="content">
        <p>
          {{ $t("legal.terms.sub4.par") }}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1
          class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium"
        >
          {{ $t("legal.terms.sub5.heading") }}
        </h1>
      </div>
      <div class="content">
        <p>
          {{ $t("legal.terms.sub5.par") }}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1
          class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium"
        >
          {{$t('legal.terms.sub6.heading')}}
        </h1>
      </div>
      <div class="content">
        <p>
          {{$t('legal.terms.sub6.par')}}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1
          class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium"
        >
          {{$t('legal.terms.sub7.heading')}}
        </h1>
      </div>
      <div class="content">
        <p>
          {{$t('legal.terms.sub7.par')}}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1
          class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium"
        >
          {{$t('legal.terms.sub8.heading')}}
        </h1>
      </div>
      <div class="content">
        <p>
          {{$t('legal.terms.sub8.par')}}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1
          class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium"
        >
          {{ $t("legal.terms.sub9.heading") }}
        </h1>
      </div>
      <div class="content">
        <p>
          {{ $t("legal.terms.sub9.par")
          }}<CustomRouteLink location="privacy-policy" class="underline text-greyText">{{
            $t("legal.terms.sub9.linkToPrivacyPolicy")
          }}</CustomRouteLink>
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1
          class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium"
        >
          {{$t('legal.terms.sub10.heading')}}
        </h1>
      </div>
      <div class="content">
        <p>
          {{$t('legal.terms.sub10.par')}}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1
          class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium"
        >
          {{$t('legal.terms.sub11.heading')}}
        </h1>
      </div>
      <div class="content">
        <p>
          {{$t('legal.terms.sub11.par')}}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1
          class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium"
        >
          {{$t('legal.terms.sub12.heading')}}
        </h1>
      </div>
      <div class="content">
        <p>
          {{$t('legal.terms.sub12.par')}}
        </p>
      </div>
    </div>
    <div class="flex flex-col mb-[50px]">
      <div class="heading mb-[16px]">
        <h1
          class="lg:text-[28px] md:text-[25px] text-[20px] leading-[23.7px] font-sans font-medium"
        >
          {{$t('legal.terms.sub13.heading')}}
        </h1>
      </div>
      <div class="content">
        <p v-html="$t('legal.terms.sub13.par')"></p>
        <p>{{$t('legal.terms.sub13.par2')}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import CustomRouteLink from '../components/customRouteLink.vue'
export default { components: { CustomRouteLink } }
</script>

<style lang="scss" scoped></style>

<template>
  <div class="stories relative overflow-hidden">
    <div class="circle-sprite max-w-[736px] w-full max-h-[736px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] rounded-full top-[-530px] right-[-110px] lg:right-[300px] md:top-[-290px] opacity-0"></div>
    <div class="square-sprite max-w-[470px] w-full max-h-[470px] h-full bg-[transparent] absolute border-solid border-[#e6e9e9] border-[1px] transform rotate-[55deg] left-[180px] bottom-[-350px] opacity-0"></div>
    <div class="grid grid-cols-1 lg:grid-cols-13">
      <div class="relative lg:col-span-4 bg-black h-[150px] md:h-[180px] lg:h-screen">
        <!-- <div id="progressBarContainer" class="hidden lg:block">
          <div id="progressBar"></div>
        </div> -->
        <img id="stories-image-slider" src="../assets/stories-img.png" alt="" width="100%" height="100%" class="h-[150px] md:h-[180px] slide-in object-cover lg:h-full" >
      </div>
      <div class="lg:col-span-7 bg-white h-[150px] md:h-[180px] lg:h-screen">
        <div class="flex flex-col lg:flex-row relative justify-between">
          <div class="heading md:px-[48px] px-[16px] lg:mt-[120px] md:mt-[40px] mt-[16px]">
            <h1 class="lg:text-[56px] md:text-[40px] text-[34px] leading-[68px]">Stories</h1>
          </div>
          <div id="stories-content" class="md:px-[50px] px-[16px] pb-[100px] min-h-[100vh] lg:h-screen lg:overflow-y-scroll">
            <div class="data pt-[20px]">
              <div class="lg:h-[max-content]">
                <div class="subheading">
                  <b class="text-[28px] block font-medium leading-[33px] mb-[24px] mt-[16px] md:mt-[40px] lg:mt-[135px]">Musings</b>
                </div>
                <a href="#" class="text-[#187CB4] underline font-light text-[18px] leading-[32px]">Musings link and brief copy goes here...</a>
              </div>
              <div class="lg:h-[max-content]">
                <div class="subheading">
                <b class="text-[28px] block font-medium leading-[33px] mb-[24px] mt-[16px] md:mt-[40px] lg:mt-[48px]">Photo Journalism</b>
                </div>
                <a href="#" class="text-[#187CB4] underline font-light text-[18px] leading-[32px]">Photojournalism link and brief copy goes here...</a>
              </div>
              <div class="lg:h-[max-content]">
                <div class="subheading">
                <b class="text-[28px] block font-medium leading-[33px] mb-[24px] mt-[16px] md:mt-[40px] lg:mt-[48px]">Podcasts</b>
                </div>
                <a href="#" class="text-[#187CB4] underline font-light text-[18px] leading-[32px]">Podcasts link and brief copy goes here...</a>
              </div>
              <div class="lg:h-[max-content]">
                <div class="subheading">
                <b class="text-[28px] block font-medium leading-[33px] mb-[24px] mt-[16px] md:mt-[40px] lg:mt-[48px]">Video</b>
                </div>
                <a href="#" class="text-[#187CB4] underline font-light text-[18px] leading-[32px]">Video link and brief copy goes here...</a>
              </div>
              <div class="lg:h-[max-content]">
                <div class="subheading">
                <b class="text-[28px] block font-medium leading-[33px] mb-[24px] mt-[16px] md:mt-[40px] lg:mt-[48px]">Q&A</b>
                </div>
                <a href="#" class="text-[#187CB4] underline font-light text-[18px] leading-[32px]">Q&A link and brief copy goes here...</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
export default {
  async mounted () {
    gsap.fromTo('.circle-sprite', { opacity: 0, scaleX: 2, scaleY: 2 }, { opacity: 1, scaleX: 1, scaleY: 1, delay: 1, duration: 2 })
    gsap.fromTo('.square-sprite', { opacity: 0, scaleX: 2, scaleY: 2 }, { opacity: 1, scaleX: 1, scaleY: 1, delay: 1, duration: 2.1 })
  }
}
</script>

<style scoped>
.stories {
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}
::-webkit-scrollbar {
  display: none;
}
#stories-image-slider {
  transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
}

.slide-in {
  animation: slide-in 1.5s forwards;
  -webkit-animation: slide-in 1.5s forwards;
}

@keyframes slide-in {
  100% { transform: translateX(0%); }
}

@-webkit-keyframes slide-in {
  100% { -webkit-transform: translateX(0%); }
}
</style>
